import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { PotentialNumSharesChange, GenericResponse$reflection, PotentialNumSharesChange$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, int32_type, union_type, record_type, string_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { map } from "./fable_modules/fable-library.3.7.22/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map as map_1, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import Button from "@material-ui/core/Button";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { loadingIndicatorSmall, snackError } from "./ViewHelpers.js";

class Model extends Record {
    constructor(Unhandled, ErrorMsg) {
        super();
        this.Unhandled = Unhandled;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ShareChanges.Model", [], Model, () => [["Unhandled", option_type(array_type(PotentialNumSharesChange$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UnhandledResponse", "DismissResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ShareChanges.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(PotentialNumSharesChange$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(PotentialNumSharesChange$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item1", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])], ["Item2", int32_type]], []]);
}

function init() {
    return new Model(void 0, void 0);
}

function update(model, msg) {
    let matchValue;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                const x_1 = res_1.fields[0];
                if (x_1.Result !== "success") {
                    return new Model(model.Unhandled, x_1.Message);
                }
                else {
                    return new Model((matchValue = model.Unhandled, (matchValue != null) ? map((z) => {
                        if (z.Id === msg.fields[1]) {
                            return new PotentialNumSharesChange(z.Id, z.Ticker, z.Market, z.Title, z.Body, z.Scraped, true);
                        }
                        else {
                            return z;
                        }
                    }, matchValue) : (void 0)), model.ErrorMsg);
                }
            }
            else {
                return new Model(model.Unhandled, "Could not dismiss. Please try again later.");
            }
        }
        case 2: {
            return new Model(model.Unhandled, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Unhandled, "Could not load unhandled. Please try again later.");
            }
            else {
                return new Model(res.fields[0], model.ErrorMsg);
            }
        }
    }
}

function fetchUnhandled(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(PotentialNumSharesChange$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/num-shares/unhandled", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(PotentialNumSharesChange$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(PotentialNumSharesChange$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function tryDismiss(msgId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        const requestPath = `/api/num-shares/dismiss/${msgId}`;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(""), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(1, _arg_2, msgId));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function drawChanges(x, dispatch) {
    return map((y) => {
        let elements, elems, value_12, elems_1, elems_2;
        return MuiHelpers_createElement(Paper, [["elevation", 1], ["style", {
            padding: 10,
            display: "flex",
            flexDirection: "column",
            gap: 5 + "px ",
        }], (elements = [createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            gap: 10 + "px ",
        }], (elems = [(value_12 = (`${y.Ticker}.${y.Market}`), createElement("span", {
            children: [value_12],
        })), createElement("span", {
            children: [y.Title],
        }), createElement("span", {
            children: [y.Scraped],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {}], (elems_1 = [createElement("p", {
            style: {
                whiteSpace: "pre-wrap",
            },
            children: y.Body,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
            display: "flex",
            justifyContent: "flex-end",
            gap: 10 + "px ",
        }], (elems_2 = toList(delay(() => (y.Handled ? singleton_1(createElement("span", {
            children: ["Handled"],
        })) : singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Dismiss"], ["onClick", (_arg) => {
            tryDismiss(y.Id, dispatch);
        }]]))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
    }, x);
}

export function ChangesInNumShares() {
    let elems;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchUnhandled(dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        padding: 1 + "vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20 + "px ",
    }], (elems = toList(delay(() => append(singleton_1(snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(2));
    })), delay(() => {
        const matchValue = model_1.Unhandled;
        if (matchValue != null) {
            const x = matchValue;
            return (x.length === 0) ? singleton_1(createElement("span", {
                children: ["No unhandled num shares changes."],
            })) : singleton_1(drawChanges(x, dispatch));
        }
        else {
            return singleton_1(loadingIndicatorSmall());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

