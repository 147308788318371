import { op_Subtraction, compare, toString as toString_1, parse, fromBits } from "./fable_modules/fable-library.3.7.22/Long.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { parse as parse_1, toString, now } from "./fable_modules/fable-library.3.7.22/Date.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import { uncurry, createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { replace } from "./fable_modules/fable-library.3.7.22/String.js";
import { trySetInputValue } from "./Utils.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { cons, empty as empty_1, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.7.22/List.js";
import DialogContent from "@material-ui/core/DialogContent";
import { loadingIndicatorSmall } from "./ViewHelpers.js";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { GenericResponse$reflection, NumSharesForm$reflection, NumSharesForm as NumSharesForm_1 } from "./Shared/ApiDataTypes.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString as toString_2 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { unit_type, equals, obj_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import DialogActions from "@material-ui/core/DialogActions";

export function NumSharesForm(numSharesFormInputProps) {
    let copyOfStruct, elements_2, children, elems, children_1;
    const onClose = numSharesFormInputProps.onClose;
    const x = numSharesFormInputProps.x;
    let patternInput;
    try {
        patternInput = [parse(x.NumShares, 511, false, 64), void 0];
    }
    catch (matchValue) {
        patternInput = [fromBits(0, 0, false), "Could not parse current number of shares as int64"];
    }
    const initialNum = patternInput[0];
    const initialError = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const shouldLog = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505((copyOfStruct = now(), toString(copyOfStruct, "yyyy-MM-dd")));
    const logDate = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(fromBits(0, 0, false));
    const setLogDiff = patternInput_3[1];
    const logDiff = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_4[1];
    const setErrMsg = useFeliz_React__React_useState_Static_1505(initialError)[1];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(initialNum);
    const numShares = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(initialError == null);
    const validNum = patternInput_7[0];
    const setValidNum = patternInput_7[1];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(true);
    const validDiff = patternInput_8[0];
    const setValidDiff = patternInput_8[1];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(true);
    const setValidDate = patternInput_9[1];
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(false);
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_4, v) => {
        onClose(void 0);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", `Update number of shares for ${x.Name}`]]), (children = singleton_1(createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems = toList(delay(() => {
        let value_11;
        return append(singleton((value_11 = (`Currently ${x.NumShares} shares`), createElement("span", {
            children: [value_11],
        }))), delay(() => append(singleton(MuiHelpers_createElement(TextField, [["style", {
            marginTop: 10,
        }], ["label", "Num shares"], ["error", !validNum], ["variant", "outlined"], ["required", true], ["defaultValue", toString_1(numShares)], ["fullWidth", true], ["autoFocus", true], ["onChange", (e) => {
            const y = Browser_Types_Event__Event_get_Value(e);
            try {
                const n = parse(replace(replace(y, " ", ""), ",", ""), 511, false, 64);
                if (compare(n, fromBits(0, 0, false)) >= 0) {
                    patternInput_6[1](n);
                    setValidNum(true);
                    if (!patternInput_10[0]) {
                        const diff = op_Subtraction(n, initialNum);
                        setLogDiff(diff);
                        trySetInputValue("diff-input", toString_1(diff));
                    }
                }
                else {
                    setValidNum(false);
                }
            }
            catch (matchValue_2) {
                setValidNum(false);
            }
        }]])), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["checked", shouldLog], ["onChange", (e_1) => {
            const b = Browser_Types_Event__Event_get_Checked(e_1);
            if (b) {
                try {
                    setLogDiff(op_Subtraction(numShares, initialNum));
                }
                catch (matchValue_3) {
                }
            }
            patternInput_1[1](b);
        }], ["label", "Log change"], ["control", MuiHelpers_createElement(Checkbox, [])]])), delay(() => (shouldLog ? append(singleton(MuiHelpers_createElement(TextField, [["type", "date"], ["label", "Diff date"], ["value", logDate], ["error", !patternInput_9[0]], ["fullWidth", true], ["style", {
            margin: ((10 + "px ") + 0) + "px",
        }], ["onChange", (e_2) => {
            const d_8 = Browser_Types_Event__Event_get_Value(e_2);
            try {
                patternInput_2[1](toString(parse_1(d_8), "yyyy-MM-dd"));
                setValidDate(true);
            }
            catch (matchValue_4) {
                setValidDate(false);
            }
        }]])), delay(() => singleton(MuiHelpers_createElement(TextField, [["id", "diff-input"], ["label", "#Shares diff"], ["error", !validDiff], ["variant", "outlined"], ["required", true], ["defaultValue", toString_1(logDiff)], ["fullWidth", true], ["onChange", (e_3) => {
            const y_1 = Browser_Types_Event__Event_get_Value(e_3);
            try {
                setLogDiff(parse(replace(y_1, " ", ""), 511, false, 64));
                setValidDiff(true);
                patternInput_10[1](true);
            }
            catch (matchValue_5) {
                setValidDiff(false);
            }
        }]])))) : empty())))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput_4[0] ? singleton(loadingIndicatorSmall()) : append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Cancel"], ["onClick", (_arg_5) => {
        onClose(void 0);
    }]])), delay(() => singleton(MuiHelpers_createElement(Button, [["size", "small"], ["disabled", (!validNum) ? true : (!validDiff)], ["children", "Update"], ["onClick", (_arg_6) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, decoder, data_1, caseStrategy_3, extra_3;
            if (validNum) {
                setProcessing(true);
                const log = (!shouldLog) ? (void 0) : {
                    Date: logDate,
                    Diff: toString_1(logDiff),
                };
                const req = new NumSharesForm_1(x.Id, toString_1(numShares), log);
                return ((body = Auto_generateBoxedEncoder_Z20B7B430(NumSharesForm$reflection(), void 0, void 0, void 0)(req), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString_2(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset/num-shares", properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue_1;
                                return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue_1 = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue_1.fields[0]))) : (new FSharpResult$2(0, matchValue_1.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })())))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 0) {
                        const r = res.fields[0];
                        if (r.Result === "success") {
                            onClose(numShares);
                            return Promise.resolve();
                        }
                        else {
                            setProcessing(false);
                            setErrMsg(r.Message);
                            return Promise.resolve();
                        }
                    }
                    else {
                        console.log(some(res.fields[0]));
                        setErrMsg("Could not update number of shares");
                        return Promise.resolve();
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        }));
        pr_1.then();
    }]]))))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

