import { Record, Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { record_type, option_type, string_type, union_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { createObj, equals } from "../fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.22/List.js";
import { pageHeader, snackError } from "../ViewHelpers.js";
import { userReports } from "./UserReports.js";
import { groupReports } from "./GroupReports.js";
import { ContentReports } from "./ContentReports.js";
import { useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.64.0/React.fs.js";

class SubView extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Content", "Users", "Groups"];
    }
}

function SubView$reflection() {
    return union_type("Reports.SubView", [], SubView, () => [[], [], []]);
}

class Model extends Record {
    constructor(CurrentView, ErrorMsg) {
        super();
        this.CurrentView = CurrentView;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Reports.Model", [], Model, () => [["CurrentView", SubView$reflection()], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Goto", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Reports.Message", [], Message, () => [[["Item", SubView$reflection()]], []]);
}

function init() {
    return new Model(new SubView(0), void 0);
}

function update(model, msg) {
    if (msg.tag === 1) {
        return new Model(model.CurrentView, void 0);
    }
    else {
        return new Model(msg.fields[0], model.ErrorMsg);
    }
}

function view(model, dispatch) {
    let elems_2;
    const navButton = (txt, x) => {
        let elems;
        const active = equals(model.CurrentView, x);
        return createElement("div", createObj(ofArray([["style", {
            margin: 5,
        }], (elems = [MuiHelpers_createElement(Button, toList(delay(() => append(singleton(["size", "small"]), delay(() => append(active ? append(singleton(["variant", "contained"]), delay(() => singleton(["color", "primary"]))) : singleton(["variant", "outlined"]), delay(() => append(singleton(["children", txt]), delay(() => singleton(["onClick", (_arg) => {
            dispatch(new Message(0, x));
        }]))))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    return createElement("div", createObj(ofArray([["style", {
        padding: 2 + "vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems_2 = toList(delay(() => append(singleton(snackError(model.ErrorMsg, () => {
        dispatch(new Message(1));
    })), delay(() => append(singleton(pageHeader("Reports")), delay(() => {
        let elems_1;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            width: 100 + "%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: 20,
        }], (elems_1 = [navButton("Content", new SubView(0)), navButton("Users", new SubView(1)), navButton("Groups", new SubView(2))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
            const matchValue = model.CurrentView;
            switch (matchValue.tag) {
                case 1: {
                    return singleton(userReports());
                }
                case 2: {
                    return singleton(groupReports());
                }
                default: {
                    return singleton(createElement(ContentReports, null));
                }
            }
        }));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Reports() {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    return view(patternInput[0], patternInput[1]);
}

