import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { uncurry, createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { unit_type, equals, obj_type, string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { GenericResponse$reflection } from "./Shared/ApiDataTypes.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty as empty_1, singleton as singleton_1 } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { trySetInputValue } from "./Utils.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";

export function ProhibitForm() {
    let elems_1, elems;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        ErrorMsg: void 0,
        Username: "",
    });
    const updateState = patternInput[1];
    const state = patternInput[0];
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 5 + "vw",
    }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
        maxWidth: 300,
    }], (elems = [MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["id", "username-input"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["autoFocus", true]), delay(() => append(singleton(["onChange", (e) => {
        updateState({
            ErrorMsg: state.ErrorMsg,
            Username: Browser_Types_Event__Event_get_Value(e),
        });
    }]), delay(() => {
        let matchValue_1, errMsg;
        return append((matchValue_1 = state.ErrorMsg, (matchValue_1 != null) ? ((errMsg = matchValue_1, append(singleton(["error", true]), delay(() => singleton(["helperText", errMsg]))))) : ((empty()))), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["size", "small"]), delay(() => singleton(["label", "Username"])))))));
    }))))))))))), MuiHelpers_createElement(Button, [["disabled", state.Username.length === 0], ["onClick", (_arg_3) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, decoder, data_1, caseStrategy_3, extra_3;
            if (state.Username.length > 0) {
                return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(state.Username), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                    let properties_2, headers_2;
                    try {
                        const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/user/prohibit-username", properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue;
                                return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                    }
                })())))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 0) {
                        const x_1 = res.fields[0];
                        if (x_1.Result === "success") {
                            updateState({
                                ErrorMsg: void 0,
                                Username: "",
                            });
                            trySetInputValue("username-input", "");
                            return Promise.resolve();
                        }
                        else {
                            updateState({
                                ErrorMsg: x_1.Message,
                                Username: state.Username,
                            });
                            return Promise.resolve();
                        }
                    }
                    else {
                        updateState({
                            ErrorMsg: "Could not register",
                            Username: state.Username,
                        });
                        return Promise.resolve();
                    }
                });
            }
            else {
                updateState({
                    ErrorMsg: "Empty username input",
                    Username: state.Username,
                });
                return Promise.resolve();
            }
        }));
        pr_1.then();
    }], ["size", "small"], ["variant", "outlined"], ["children", "Prohibit"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

