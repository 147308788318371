import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { createElement } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export function snackError(errMsg, dismiss) {
    return MuiHelpers_createElement(Snackbar, [["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["open", errMsg != null], ["onClose", (_arg_1, v) => {
        dismiss();
    }], ["autoHideDuration", 20000], ["children", MuiHelpers_createElement(Alert, [["severity", "error"], ["variant", "filled"], ["onClose", (_arg_2) => {
        dismiss();
    }], ["children", (errMsg == null) ? "" : errMsg]])]]);
}

export function snackInfo(infoMsg, dismiss) {
    return MuiHelpers_createElement(Snackbar, [["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["open", infoMsg != null], ["onClose", (_arg_1, v) => {
        dismiss();
    }], ["autoHideDuration", 20000], ["children", MuiHelpers_createElement(Alert, [["severity", "info"], ["variant", "filled"], ["onClose", (_arg_2) => {
        dismiss();
    }], ["children", (infoMsg == null) ? "" : infoMsg]])]]);
}

export function pageHeader(txt) {
    return createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
            margin: 20,
        },
        children: txt,
    });
}

export function loadingIndicatorSmall() {
    return MuiHelpers_createElement(CircularProgress, [["size", 15]]);
}

