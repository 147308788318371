import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { Asset, Market$reflection, Asset$reflection } from "./Shared/ApiDataTypes.js";
import { tuple_type, unit_type, equals, obj_type, class_type, int32_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { sortBy, append, map, tryFind } from "./fable_modules/fable-library.3.7.22/Array.js";
import { toString } from "./fable_modules/fable-library.3.7.22/Long.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map as map_1, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString as toString_1 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { compareArrays, comparePrimitives, createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { createElement } from "react";
import { pageHeader, snackError } from "./ViewHelpers.js";
import { IntraQuotesForm as IntraQuotesForm_1, AssetQuoteUpload } from "./QuoteUpload.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NumSharesForm } from "./NumShares.js";
import { AssetCsvUpload } from "./AssetUpload.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

class Model extends Record {
    constructor(Assets, Markets, SelectedMarket, Edit, HistoricQuotesForm, IntraQuotesForm, ShowAddForm, AssetsCsvForm, SharesForm, ErrorMsg) {
        super();
        this.Assets = Assets;
        this.Markets = Markets;
        this.SelectedMarket = SelectedMarket;
        this.Edit = Edit;
        this.HistoricQuotesForm = HistoricQuotesForm;
        this.IntraQuotesForm = IntraQuotesForm;
        this.ShowAddForm = ShowAddForm;
        this.AssetsCsvForm = AssetsCsvForm;
        this.SharesForm = SharesForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Assets.Model", [], Model, () => [["Assets", option_type(array_type(Asset$reflection()))], ["Markets", option_type(array_type(Market$reflection()))], ["SelectedMarket", option_type(Market$reflection())], ["Edit", option_type(Asset$reflection())], ["HistoricQuotesForm", option_type(Asset$reflection())], ["IntraQuotesForm", bool_type], ["ShowAddForm", bool_type], ["AssetsCsvForm", bool_type], ["SharesForm", option_type(Asset$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AssetsResponse", "MarketsResponse", "SelectMarket", "Edit", "EditResponse", "ToggleAddForm", "AddResponse", "ToggleQuoteForm", "ToggleIntraForm", "ToggleSharesForm", "ToggleCsvForm", "NumSharesUpdated", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Assets.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Asset$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Asset$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Market$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Market$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", option_type(Asset$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Asset$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Asset$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Asset$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Asset$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Asset$reflection())]], [["Item", bool_type]], [["Item", option_type(Asset$reflection())]], [["Item", bool_type]], [["Item1", int32_type], ["Item2", class_type("System.Int64")]], []]);
}

function init() {
    return new Model(void 0, void 0, void 0, void 0, void 0, false, false, false, void 0, void 0);
}

function update(model, msg) {
    let matchValue_1, matchValue_2;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, "Could not load markets");
            }
            else {
                return new Model(model.Assets, res_1.fields[0], model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
            }
        }
        case 2: {
            const matchValue = model.Markets;
            if (matchValue != null) {
                const _arg = tryFind((z) => (z.Id === msg.fields[0]), matchValue);
                if (_arg != null) {
                    return new Model(void 0, model.Markets, _arg, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
                }
                else {
                    return model;
                }
            }
            else {
                return model;
            }
        }
        case 3: {
            return new Model(model.Assets, model.Markets, model.SelectedMarket, msg.fields[0], model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
        }
        case 4: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                const err_2 = res_2.fields[0];
                return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, (err_2.tag === 2) ? (((err_2.fields[0].status) === 401) ? "Authentication failed" : "Could not update asset.") : "Could not update asset.");
            }
            else {
                const x_4 = res_2.fields[0];
                return new Model((matchValue_1 = model.Assets, (matchValue_1 != null) ? map((s) => {
                    if (s.Id === x_4.Id) {
                        return x_4;
                    }
                    else {
                        return s;
                    }
                }, matchValue_1) : (void 0)), model.Markets, model.SelectedMarket, void 0, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
            }
        }
        case 5: {
            return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, !model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
        }
        case 6: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                const err_3 = res_3.fields[0];
                return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, (err_3.tag === 2) ? (((err_3.fields[0].status) === 401) ? "Authentication failed" : "Could not add asset.") : "Could not add asset.");
            }
            else {
                const x_7 = res_3.fields[0];
                return new Model((matchValue_2 = model.Assets, (matchValue_2 != null) ? append([x_7], matchValue_2) : [x_7]), model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, false, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
            }
        }
        case 7: {
            return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, msg.fields[0], model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
        }
        case 8: {
            return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, msg.fields[0], model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
        }
        case 9: {
            return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, msg.fields[0], model.ErrorMsg);
        }
        case 11: {
            const matchValue_3 = model.Assets;
            if (matchValue_3 != null) {
                return new Model(map((z_3) => {
                    if (z_3.Id !== msg.fields[0]) {
                        return z_3;
                    }
                    else {
                        return new Asset(z_3.Id, z_3.ISIN, z_3.Name, toString(msg.fields[1]), z_3.AssetType, z_3.Ticker, z_3.Currency, z_3.MarketId, z_3.Delisted);
                    }
                }, matchValue_3), model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, void 0, model.ErrorMsg);
            }
            else {
                return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, void 0, model.ErrorMsg);
            }
        }
        case 10: {
            return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, msg.fields[0], model.SharesForm, model.ErrorMsg);
        }
        case 12: {
            return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Assets, model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, "Could not load assets");
            }
            else {
                return new Model(res.fields[0], model.Markets, model.SelectedMarket, model.Edit, model.HistoricQuotesForm, model.IntraQuotesForm, model.ShowAddForm, model.AssetsCsvForm, model.SharesForm, model.ErrorMsg);
            }
        }
    }
}

function fetchAssets(marketId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = `/api/asset/market/${marketId}`;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Asset$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Asset$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Asset$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchMarkets(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Market$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/market", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Market$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Market$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function addAsset(x, pw, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(tuple_type(string_type, Asset$reflection()), void 0, void 0, void 0)([pw, x]), (decoder = Auto_generateBoxedDecoder_79988AEF(Asset$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Asset$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Asset$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(6, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function editAsset(x, pw, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(tuple_type(string_type, Asset$reflection()), void 0, void 0, void 0)([pw, x]), (decoder = Auto_generateBoxedDecoder_79988AEF(Asset$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "PUT"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Asset$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Asset$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function emptyAsset(x) {
    return new Asset(-1, "", "", "0", 1, "", "", x, void 0);
}

function AddForm(addFormInputProps) {
    let elements_4, children, elements_1, elements, children_1;
    const dispatch = addFormInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(emptyAsset(addFormInputProps.mId));
    const updateAsset = patternInput[1];
    const asset = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const pw = patternInput_1[0];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        dispatch(new Message(5));
    }], (elements_4 = [MuiHelpers_createElement(DialogTitle, [["children", "Add asset"]]), (children = ofArray([MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.ISIN]), delay(() => append_1(singleton_1(["autoFocus", true]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "ISIN"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e) => {
        updateAsset(new Asset(asset.Id, Browser_Types_Event__Event_get_Value(e), asset.Name, asset.NumShares, asset.AssetType, asset.Ticker, asset.Currency, asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => ((asset.ISIN.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.Name]), delay(() => append_1(singleton_1(["autoFocus", true]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Name"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_1) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, Browser_Types_Event__Event_get_Value(e_1), asset.NumShares, asset.AssetType, asset.Ticker, asset.Currency, asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1((asset.Name.length === 0) ? singleton_1(["error", true]) : empty_1(), delay(() => singleton_1(["style", {
        marginTop: 20,
        marginBottom: 20,
    }])))))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.Ticker]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Ticker"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_2) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, asset.Name, asset.NumShares, asset.AssetType, Browser_Types_Event__Event_get_Value(e_2), asset.Currency, asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1((asset.Ticker.length === 0) ? singleton_1(["error", true]) : empty_1(), delay(() => singleton_1(["style", {
        marginBottom: 20,
    }])))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.Currency]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Currency"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_3) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, asset.Name, asset.NumShares, asset.AssetType, asset.Ticker, Browser_Types_Event__Event_get_Value(e_3), asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["style", {
        marginBottom: 20,
    }]), delay(() => ((asset.Currency.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(FormControl, [["style", {
        margin: 20,
    }], (elements_1 = [MuiHelpers_createElement(Select, [["value", asset.AssetType], ["label", "Asset type"], ["onChange", (e_5, _arg_2) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, asset.Name, asset.NumShares, Browser_Types_Event__Event_get_Value(e_5), asset.Ticker, asset.Currency, asset.MarketId, asset.Delisted));
    }], (elements = [MuiHelpers_createElement(MenuItem, [["value", 1], ["children", "Stock"]]), MuiHelpers_createElement(MenuItem, [["value", 2], ["children", "Crypto"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", pw]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Your password"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_6) => {
        patternInput_1[1](Browser_Types_Event__Event_get_Value(e_6));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["type", "password"]), delay(() => ((pw.length === 0) ? singleton_1(["error", true]) : empty_1()))))))))))))))))))))]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["onClick", (_arg_3) => {
        dispatch(new Message(3, void 0));
    }], ["children", "Cancel"]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", ((asset.Name.length === 0) ? true : (asset.Ticker.length === 0)) ? true : (asset.Currency.length === 0)], ["onClick", (_arg_4) => {
        if ((((asset.Name.length > 0) && (asset.Ticker.length > 0)) && (asset.Currency.length > 0)) && (pw.length > 0)) {
            addAsset(asset, pw, dispatch);
        }
    }], ["children", "Add"]])]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]);
}

function EditForm(editFormInputProps) {
    let elements_4, children, elements_1, elements, children_1;
    const dispatch = editFormInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(editFormInputProps.s);
    const updateAsset = patternInput[1];
    const asset = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const pw = patternInput_1[0];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        dispatch(new Message(3, void 0));
    }], (elements_4 = [MuiHelpers_createElement(DialogTitle, [["children", "Update asset"]]), (children = ofArray([MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.ISIN]), delay(() => append_1(singleton_1(["autoFocus", true]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "ISIN"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e) => {
        updateAsset(new Asset(asset.Id, Browser_Types_Event__Event_get_Value(e), asset.Name, asset.NumShares, asset.AssetType, asset.Ticker, asset.Currency, asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => ((asset.ISIN.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.Name]), delay(() => append_1(singleton_1(["autoFocus", true]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Name"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_1) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, Browser_Types_Event__Event_get_Value(e_1), asset.NumShares, asset.AssetType, asset.Ticker, asset.Currency, asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1((asset.Name.length === 0) ? singleton_1(["error", true]) : empty_1(), delay(() => singleton_1(["style", {
        marginTop: 20,
        marginBottom: 20,
    }])))))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.Ticker]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Ticker"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_2) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, asset.Name, asset.NumShares, asset.AssetType, Browser_Types_Event__Event_get_Value(e_2), asset.Currency, asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1((asset.Ticker.length === 0) ? singleton_1(["error", true]) : empty_1(), delay(() => singleton_1(["style", {
        marginBottom: 20,
    }])))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", asset.Currency]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Currency"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_3) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, asset.Name, asset.NumShares, asset.AssetType, asset.Ticker, Browser_Types_Event__Event_get_Value(e_3), asset.MarketId, asset.Delisted));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["style", {
        marginBottom: 20,
    }]), delay(() => ((asset.Currency.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(FormControl, [["style", {
        margin: 20,
    }], (elements_1 = [MuiHelpers_createElement(Select, [["value", asset.AssetType], ["label", "Asset type"], ["onChange", (e_5, _arg_2) => {
        updateAsset(new Asset(asset.Id, asset.ISIN, asset.Name, asset.NumShares, Browser_Types_Event__Event_get_Value(e_5), asset.Ticker, asset.Currency, asset.MarketId, asset.Delisted));
    }], (elements = [MuiHelpers_createElement(MenuItem, [["value", 1], ["children", "Stock"]]), MuiHelpers_createElement(MenuItem, [["value", 2], ["children", "Crypto"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", pw]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Your password"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_6) => {
        patternInput_1[1](Browser_Types_Event__Event_get_Value(e_6));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["type", "password"]), delay(() => ((pw.length === 0) ? singleton_1(["error", true]) : empty_1()))))))))))))))))))))]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["onClick", (_arg_3) => {
        dispatch(new Message(3, void 0));
    }], ["children", "Cancel"]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", ((asset.Name.length === 0) ? true : (asset.Ticker.length === 0)) ? true : (asset.Currency.length === 0)], ["onClick", (_arg_4) => {
        if ((((asset.Name.length > 0) && (asset.Ticker.length > 0)) && (asset.Currency.length > 0)) && (pw.length > 0)) {
            editAsset(asset, pw, dispatch);
        }
    }], ["children", "Update"]])]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]);
}

function view(model, dispatch) {
    let elems_11;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems_11 = toList(delay(() => append_1(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(12));
    })), delay(() => {
        let matchValue, qf;
        return append_1((matchValue = model.HistoricQuotesForm, (matchValue != null) ? ((qf = matchValue, singleton_1(createElement(AssetQuoteUpload, {
            asset: qf,
            onClose: () => {
                dispatch(new Message(7, void 0));
            },
        })))) : ((empty_1()))), delay(() => append_1(model.IntraQuotesForm ? singleton_1(createElement(IntraQuotesForm_1, {
            onClose: () => {
                dispatch(new Message(8, false));
            },
        })) : empty_1(), delay(() => append_1(singleton_1(pageHeader("Assets")), delay(() => {
            let matchValue_1, x, elements_1, matchValue_2, elements;
            return append_1((matchValue_1 = model.Markets, (matchValue_1 != null) ? ((x = matchValue_1, singleton_1(MuiHelpers_createElement(FormControl, [["style", {
                margin: 20,
            }], (elements_1 = [MuiHelpers_createElement(Select, [["value", (matchValue_2 = model.SelectedMarket, (matchValue_2 != null) ? matchValue_2.Id : -1)], ["label", "Market"], ["onChange", (e_2, _arg_3) => {
                const e_1 = Browser_Types_Event__Event_get_Value(e_2);
                dispatch(new Message(2, e_1));
                fetchAssets(e_1, dispatch);
            }], (elements = [MuiHelpers_createElement(MenuItem, [["hidden", true], ["value", -1], ["children", "Select market"]]), map((y_2) => MuiHelpers_createElement(MenuItem, [["value", y_2.Id], ["children", y_2.Name]]), sortBy((y) => y.Name, x, {
                Compare: comparePrimitives,
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])))) : singleton_1(MuiHelpers_createElement(CircularProgress, []))), delay(() => {
                const matchValue_3 = model.SelectedMarket;
                if (matchValue_3 != null) {
                    const m = matchValue_3;
                    const matchValue_4 = model.Assets;
                    if (matchValue_4 != null) {
                        const x_2 = matchValue_4;
                        return append_1(model.ShowAddForm ? singleton_1(createElement(AddForm, {
                            mId: m.Id,
                            dispatch: dispatch,
                        })) : empty_1(), delay(() => {
                            let elems_2;
                            return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {}], (elems_2 = [MuiHelpers_createElement(Button, [["variant", "outlined"], ["color", "primary"], ["children", "Add asset"], ["onClick", (_arg_4) => {
                                dispatch(new Message(5));
                            }], ["style", {
                                margin: 20,
                            }]]), MuiHelpers_createElement(Button, [["variant", "outlined"], ["color", "primary"], ["children", "Upload assets CSV"], ["onClick", (_arg_5) => {
                                dispatch(new Message(10, true));
                            }], ["style", {
                                margin: 20,
                            }]]), MuiHelpers_createElement(Button, [["variant", "outlined"], ["color", "primary"], ["children", "Upload intraday quotes"], ["onClick", (_arg_6) => {
                                dispatch(new Message(8, !model.IntraQuotesForm));
                            }], ["style", {
                                margin: 20,
                            }]]), createElement("a", {
                                href: "https://live.euronext.com/index.php/nb/markets/oslo/equities/list",
                                target: "_blank",
                                children: "Euronext Oslo",
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                                let matchValue_5, s;
                                return append_1((matchValue_5 = model.Edit, (matchValue_5 != null) ? ((s = matchValue_5, singleton_1(createElement(EditForm, {
                                    s: s,
                                    dispatch: dispatch,
                                })))) : ((empty_1()))), delay(() => {
                                    let matchValue_6, sf;
                                    return append_1((matchValue_6 = model.SharesForm, (matchValue_6 != null) ? ((sf = matchValue_6, singleton_1(createElement(NumSharesForm, {
                                        x: sf,
                                        onClose: (newNum) => {
                                            if (newNum != null) {
                                                dispatch(new Message(11, sf.Id, newNum));
                                            }
                                            else {
                                                dispatch(new Message(9, void 0));
                                            }
                                        },
                                    })))) : ((empty_1()))), delay(() => append_1(model.AssetsCsvForm ? singleton_1(createElement(AssetCsvUpload, {
                                        onClose: () => {
                                            dispatch(new Message(10, false));
                                            fetchAssets(m.Id, dispatch);
                                        },
                                    })) : empty_1(), delay(() => {
                                        let children_3, elements_7, children_1, children, children_2;
                                        return singleton_1((children_3 = singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_7 = [(children_1 = singleton((children = ofArray([MuiHelpers_createElement(TableCell, [["children", "Name"]]), MuiHelpers_createElement(TableCell, [["children", "ISIN"]]), MuiHelpers_createElement(TableCell, [["children", "Ticker"]]), MuiHelpers_createElement(TableCell, [["children", "Currency"]]), MuiHelpers_createElement(TableCell, [["children", "# Shares"]]), MuiHelpers_createElement(TableCell, [["children", "Type"]]), MuiHelpers_createElement(TableCell, []), MuiHelpers_createElement(TableCell, [])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton(map((y_5) => {
                                            let elems_7, elements_4, elements_5;
                                            return MuiHelpers_createElement(TableRow, [["style", createObj(toList(delay(() => ((y_5.Delisted != null) ? singleton_1(["backgroundColor", "#D3D3D3"]) : empty_1()))))], (elems_7 = [MuiHelpers_createElement(TableCell, [["children", y_5.Name]]), MuiHelpers_createElement(TableCell, [["children", y_5.ISIN]]), MuiHelpers_createElement(TableCell, [["children", y_5.Ticker]]), MuiHelpers_createElement(TableCell, [["children", y_5.Currency]]), MuiHelpers_createElement(TableCell, [["style", {
                                                textDecorationLine: "underline",
                                                cursor: "pointer",
                                            }], ["onClick", (_arg_8) => {
                                                dispatch(new Message(9, y_5));
                                            }], ["children", y_5.NumShares]]), MuiHelpers_createElement(TableCell, [["children", (y_5.AssetType === 1) ? "Stock" : ((y_5.AssetType === 2) ? "Crypto" : "Unregistered")]]), MuiHelpers_createElement(TableCell, [(elements_4 = [MuiHelpers_createElement(Button, [["size", "small"], ["onClick", (_arg_9) => {
                                                dispatch(new Message(3, y_5));
                                            }], ["children", "Edit"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]), MuiHelpers_createElement(TableCell, [(elements_5 = [MuiHelpers_createElement(Button, [["size", "small"], ["onClick", (_arg_10) => {
                                                dispatch(new Message(7, y_5));
                                            }], ["children", "Upload quotes"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]);
                                        }, sortBy((y_3) => [y_3.Delisted != null, y_3.Name], x_2, {
                                            Compare: compareArrays,
                                        }))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_7))])])), MuiHelpers_createElement(TableContainer, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])));
                                    }))));
                                }));
                            }));
                        }));
                    }
                    else {
                        return singleton_1(MuiHelpers_createElement(CircularProgress, []));
                    }
                }
                else {
                    return singleton_1(createElement("span", {
                        children: ["No market selected"],
                    }));
                }
            }));
        }))))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])));
}

export function Assets() {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchMarkets(dispatch);
    });
    return view(patternInput[0], dispatch);
}

