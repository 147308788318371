import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import { uncurry, comparePrimitives, createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { empty as empty_1, map as map_1, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { loadingIndicatorSmall, snackError } from "./ViewHelpers.js";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import { cons, empty, item as item_2, length, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.7.22/List.js";
import TableRow from "@material-ui/core/TableRow";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import TableHead from "@material-ui/core/TableHead";
import { sortBy, map } from "./fable_modules/fable-library.3.7.22/Array.js";
import TableBody from "@material-ui/core/TableBody";
import { rangeDouble } from "./fable_modules/fable-library.3.7.22/Range.js";
import { map as map_2, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { GenericResponse$reflection, ParsedAsset$reflection, CsvForm$reflection, CsvForm } from "./Shared/ApiDataTypes.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { unit_type, equals, obj_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export function AssetCsvUpload(assetCsvUploadInputProps) {
    let elements_7, children_4, elems_6, children_5;
    const onClose = assetCsvUploadInputProps.onClose;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const content = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const parsed = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_2[1];
    const processing = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_3[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_7, v) => {
        onClose();
    }], (elements_7 = [MuiHelpers_createElement(DialogTitle, [["children", "Upload assets"]]), (children_4 = singleton_1(createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_6 = toList(delay(() => append(singleton(snackError(patternInput_3[0], () => {
        setErrMsg(void 0);
    })), delay(() => {
        let elements_4, children_1, children, children_3;
        const matchValue_2 = parsed;
        if (matchValue_2 != null) {
            const parsed_1 = matchValue_2;
            return singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_4 = [(children_1 = singleton_1((children = ofArray([MuiHelpers_createElement(TableCell, [["children", "ISIN"]]), MuiHelpers_createElement(TableCell, [["children", "Name"]]), MuiHelpers_createElement(TableCell, [["children", "Ticker"]]), MuiHelpers_createElement(TableCell, [["children", "Currency"]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_3 = singleton_1(map((r_2) => {
                const children_2 = ofArray([MuiHelpers_createElement(TableCell, [["children", r_2.ISIN]]), MuiHelpers_createElement(TableCell, [["children", r_2.Name]]), MuiHelpers_createElement(TableCell, [["children", r_2.Ticker]]), MuiHelpers_createElement(TableCell, [["children", r_2.Currency]])]);
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]);
            }, sortBy((r_1) => r_1.Name, parsed_1, {
                Compare: comparePrimitives,
            }))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])]));
        }
        else {
            return append(singleton(createElement("span", {
                children: "Will only parse XOSL assets",
            })), delay(() => {
                let elems;
                return append(singleton(createElement("label", createObj(ofArray([["style", {
                    cursor: "pointer",
                    textAlign: "center",
                }], (elems = [createElement("i", {
                    style: {
                        display: "block",
                        fontSize: 2 + "rem",
                    },
                    className: "fas fa-cloud-upload-alt",
                }), (content == null) ? "Choose a .csv file" : "Choose another .csv file", createElement("input", {
                    type: "file",
                    accept: ".csv",
                    style: {
                        display: "none",
                    },
                    onChange: (ev) => {
                        const fileList = ev.target.files;
                        if (!(fileList == null)) {
                            const x_3 = toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                            try {
                                if (length(x_3) > 0) {
                                    const file = item_2(0, x_3);
                                    const filename = file.name;
                                    console.log(some(`filesize: ${file.size}`));
                                    console.log(some(`filename: ${filename} (${file.name})`));
                                    const reader = new FileReader();
                                    reader.addEventListener("load", (_arg_9) => {
                                        const content_1 = reader.result;
                                        patternInput[1](content_1);
                                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                            let body, decoder, data_1, caseStrategy_3, extra_3;
                                            setProcessing(true);
                                            const req = new CsvForm(content_1);
                                            return ((body = Auto_generateBoxedEncoder_Z20B7B430(CsvForm$reflection(), void 0, void 0, void 0)(req), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(ParsedAsset$reflection()), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                                                let properties_2, headers_2;
                                                try {
                                                    const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_2((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                                                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset/parse-csv", properties_3).then((_arg) => {
                                                        let response_1, decoder_1_1;
                                                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(ParsedAsset$reflection()), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                                            let matchValue;
                                                            return Promise.resolve(equals(array_type(ParsedAsset$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                                                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                                    }))));
                                                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                                                }
                                                catch (exn) {
                                                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                                                }
                                            })())))))).then((_arg_2) => {
                                                const res = _arg_2;
                                                if (res.tag === 1) {
                                                    console.log(some(res.fields[0]));
                                                    setErrMsg("Could not upload .csv file");
                                                    setProcessing(false);
                                                    return Promise.resolve();
                                                }
                                                else {
                                                    patternInput_1[1](res.fields[0]);
                                                    setProcessing(false);
                                                    return Promise.resolve();
                                                }
                                            });
                                        }));
                                        pr_1.then();
                                    });
                                    const value_31 = reader.readAsDataURL(file);
                                }
                            }
                            catch (matchValue_3) {
                            }
                        }
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => (processing ? singleton(loadingIndicatorSmall()) : empty_1())));
            }));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])), (children_5 = toList(delay(() => (processing ? singleton(loadingIndicatorSmall()) : append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Cancel"], ["onClick", (_arg_10) => {
        onClose();
    }]])), delay(() => {
        const matchValue_4 = parsed;
        if (matchValue_4 != null) {
            const p_1 = matchValue_4;
            return singleton(MuiHelpers_createElement(Button, [["size", "small"], ["disabled", content == null], ["children", "Upload"], ["onClick", (_arg_11) => {
                const pr_3 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let body_3, decoder_3, data_5, caseStrategy_12, extra_12;
                    setProcessing(true);
                    return ((body_3 = Auto_generateBoxedEncoder_Z20B7B430(array_type(ParsedAsset$reflection()), void 0, void 0, void 0)(p_1), (decoder_3 = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_5 = some(body_3), (caseStrategy_12 = (void 0), (extra_12 = (void 0), (() => {
                        let properties_6, headers_5;
                        try {
                            const properties_3_1 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, "cors")), (properties_6 = ofArray([new Types_RequestProperties(0, "POST"), (headers_5 = Helper_withContentTypeJson(data_5, empty()), new Types_RequestProperties(1, keyValueList(headers_5, 0)))]), defaultArg(map_2((data_1_2) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_12, extra_12)(data_1_2))), properties_6), data_5), properties_6)));
                            const pr_2 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset/upload-csv", properties_3_1).then((_arg_3) => {
                                let response_3, decoder_1_2;
                                return ((response_3 = _arg_3, (decoder_1_2 = defaultArg(decoder_3, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_12, extra_12)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_3.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_3.text().then((_arg_4) => {
                                    let matchValue_1;
                                    return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue_1 = fromString(uncurry(2, decoder_1_2), _arg_4), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue_1.fields[0]))) : (new FSharpResult$2(0, matchValue_1.fields[0])))));
                                })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_3))))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                            }))));
                            return pr_2.then(void 0, ((arg_7) => (new FSharpResult$2(1, new FetchError(3, arg_7)))));
                        }
                        catch (exn_1) {
                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn_1))))));
                        }
                    })())))))).then((_arg_5) => {
                        const res_1 = _arg_5;
                        if (res_1.tag === 0) {
                            const r = res_1.fields[0];
                            if (r.Result === "success") {
                                onClose();
                                return Promise.resolve();
                            }
                            else {
                                setErrMsg(r.Message);
                                setProcessing(false);
                                return Promise.resolve();
                            }
                        }
                        else {
                            setErrMsg("Could not commit assets");
                            setProcessing(false);
                            return Promise.resolve();
                        }
                    });
                }));
                pr_3.then();
            }]]));
        }
        else {
            return empty_1();
        }
    }))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_7))])]);
}

