import { toString as toString_1, Union, Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { GroupReport, GenericResponse$reflection, GroupReport$reflection } from "../Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, option_type, int32_type, array_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { parse } from "../fable_modules/fable-library.3.7.22/Int32.js";
import { append, map } from "../fable_modules/fable-library.3.7.22/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.22/String.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { int32ToString, createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import Paper from "@material-ui/core/Paper";
import { createElement } from "react";
import { BanForm } from "../BanForm.js";
import { deleteGroupForm } from "../DeleteGroupForm.js";
import { Source, imageDisplay } from "../ImageDisplay.js";
import Avatar from "@material-ui/core/Avatar";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { ReportReason_FromInt_Z524259A4 } from "../Shared/Shared.js";
import { snackInfo, snackError } from "../ViewHelpers.js";

class Model extends Record {
    constructor(Reports, Row, DismissForm, ImageDeleteForm, RedactNameForm, ErrorMsg, InfoMsg) {
        super();
        this.Reports = Reports;
        this.Row = (Row | 0);
        this.DismissForm = DismissForm;
        this.ImageDeleteForm = ImageDeleteForm;
        this.RedactNameForm = RedactNameForm;
        this.ErrorMsg = ErrorMsg;
        this.InfoMsg = InfoMsg;
    }
}

function Model$reflection() {
    return record_type("GroupReports.Model", [], Model, () => [["Reports", array_type(GroupReport$reflection())], ["Row", int32_type], ["DismissForm", option_type(int32_type)], ["ImageDeleteForm", option_type(int32_type)], ["RedactNameForm", option_type(int32_type)], ["ErrorMsg", option_type(string_type)], ["InfoMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ReportsReponse", "Dismiss", "DismissResponse", "DeleteGroupImage", "DeleteGroupImageResponse", "RedactName", "RedactNameResponse", "GroupDeleted", "DismissError", "DismissInfo"];
    }
}

function Message$reflection() {
    return union_type("GroupReports.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(GroupReport$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(GroupReport$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [], []]);
}

function init() {
    return new Model([], 0, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue_2, groupId;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Reports, model.Row, msg.fields[0], model.ImageDeleteForm, model.RedactNameForm, model.ErrorMsg, model.InfoMsg);
        }
        case 2: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                const x_2 = res_1.fields[0];
                if (x_2.Result === "success") {
                    let removeId;
                    const matchValue = model.DismissForm;
                    if (matchValue == null) {
                        try {
                            removeId = parse(x_2.Message, 511, false, 32);
                        }
                        catch (matchValue_1) {
                            removeId = -1;
                        }
                    }
                    else {
                        removeId = matchValue;
                    }
                    return new Model(model.Reports.filter((z) => (z.Id !== removeId)), model.Row, void 0, model.ImageDeleteForm, model.RedactNameForm, model.ErrorMsg, model.InfoMsg);
                }
                else {
                    return new Model(model.Reports, model.Row, void 0, model.ImageDeleteForm, model.RedactNameForm, x_2.Message, model.InfoMsg);
                }
            }
            else {
                return new Model(model.Reports, model.Row, void 0, model.ImageDeleteForm, model.RedactNameForm, "Could not dismiss report", model.InfoMsg);
            }
        }
        case 3: {
            return new Model(model.Reports, model.Row, model.DismissForm, msg.fields[0], model.RedactNameForm, model.ErrorMsg, model.InfoMsg);
        }
        case 4: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 0) {
                const x_4 = res_2.fields[0];
                if (x_4.Result === "success") {
                    return new Model((matchValue_2 = model.ImageDeleteForm, (matchValue_2 != null) ? ((groupId = (matchValue_2 | 0), map((z_1) => {
                        if (z_1.GroupId === groupId) {
                            return new GroupReport(z_1.Id, z_1.GroupId, z_1.GroupName, void 0, z_1.Public, z_1.UserId, z_1.Username, z_1.UserImageId, z_1.ReasonId, z_1.Reason);
                        }
                        else {
                            return z_1;
                        }
                    }, model.Reports))) : model.Reports), model.Row, model.DismissForm, void 0, model.RedactNameForm, model.ErrorMsg, "Group image deleted");
                }
                else {
                    return new Model(model.Reports, model.Row, model.DismissForm, void 0, model.RedactNameForm, x_4.Message, model.InfoMsg);
                }
            }
            else {
                return new Model(model.Reports, model.Row, model.DismissForm, void 0, model.RedactNameForm, "Could not delete group image", model.InfoMsg);
            }
        }
        case 5: {
            return new Model(model.Reports, model.Row, model.DismissForm, model.ImageDeleteForm, msg.fields[0], model.ErrorMsg, model.InfoMsg);
        }
        case 6: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 0) {
                const x_6 = res_3.fields[0];
                if (x_6.Result === "success") {
                    let groupId_1;
                    const matchValue_3 = model.RedactNameForm;
                    if (matchValue_3 == null) {
                        try {
                            groupId_1 = parse(x_6.Message, 511, false, 32);
                        }
                        catch (matchValue_4) {
                            groupId_1 = -1;
                        }
                    }
                    else {
                        groupId_1 = matchValue_3;
                    }
                    return new Model(map((z_2) => {
                        if (z_2.GroupId === groupId_1) {
                            return new GroupReport(z_2.Id, z_2.GroupId, "[Redacted name]", z_2.GroupImageId, z_2.Public, z_2.UserId, z_2.Username, z_2.UserImageId, z_2.ReasonId, z_2.Reason);
                        }
                        else {
                            return z_2;
                        }
                    }, model.Reports), model.Row, model.DismissForm, model.ImageDeleteForm, void 0, model.ErrorMsg, "Name redacted");
                }
                else {
                    return new Model(model.Reports, model.Row, model.DismissForm, model.ImageDeleteForm, void 0, x_6.Message, model.InfoMsg);
                }
            }
            else {
                return new Model(model.Reports, model.Row, model.DismissForm, model.ImageDeleteForm, void 0, "Could not redact name", model.InfoMsg);
            }
        }
        case 7: {
            return new Model(model.Reports.filter((z_3) => (z_3.GroupId !== msg.fields[0])), model.Row, model.DismissForm, model.ImageDeleteForm, model.RedactNameForm, model.ErrorMsg, "Group deleted");
        }
        case 8: {
            return new Model(model.Reports, model.Row, model.DismissForm, model.ImageDeleteForm, model.RedactNameForm, void 0, model.InfoMsg);
        }
        case 9: {
            return new Model(model.Reports, model.Row, model.DismissForm, model.ImageDeleteForm, model.RedactNameForm, model.ErrorMsg, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Reports, model.Row, model.DismissForm, model.ImageDeleteForm, model.RedactNameForm, "Could not fetch reports", model.InfoMsg);
            }
            else {
                const y = append(model.Reports, res.fields[0]);
                return new Model(y, y.length, model.DismissForm, model.ImageDeleteForm, model.RedactNameForm, model.ErrorMsg, model.InfoMsg);
            }
        }
    }
}

function fetchReports(row, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("/api/report/groups?row=%i"))(row);
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(GroupReport$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(GroupReport$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(GroupReport$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
}

function dismiss(reportId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(reportId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/group/dismiss", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(2, _arg_2));
            return Promise.resolve();
        });
    }));
}

function deleteGroupImage(groupId, imageId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(3, groupId));
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(imageId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/groupimage/delete", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
}

function redactName(groupId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(5, groupId));
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(groupId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/group/name", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(6, _arg_2));
            return Promise.resolve();
        });
    }));
}

const report = FunctionComponent_Of_Z5A158BBF((props) => {
    let elems_3;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const verifyDismiss = patternInput[0];
    const setVerifyDismiss = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const verifyGroupImg = patternInput_1[0];
    const setGroupImg = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setBanForm = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const showGroupImg = patternInput_3[0];
    const setShowGroupImg = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const showUserImg = patternInput_4[0];
    const setShowUserImg = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const verifyName = patternInput_5[0];
    const setName = patternInput_5[1];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteForm = patternInput_6[1];
    const deleteForm = patternInput_6[0];
    const actionButton = (title, active, onClick) => MuiHelpers_createElement(Button, toList(delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1((!active) ? singleton_1(["variant", "outlined"]) : append_1(singleton_1(["variant", "contained"]), delay(() => singleton_1(["color", "primary"]))), delay(() => append_1(singleton_1(["style", {
        marginLeft: 5,
    }]), delay(() => append_1(singleton_1(["children", title]), delay(() => singleton_1(["onClick", onClick]))))))))))));
    return MuiHelpers_createElement(Paper, [["elevation", 2], ["style", {
        padding: 10,
        marginBottom: 20,
        minWidth: 350,
        maxWidth: 500,
    }], (elems_3 = toList(delay(() => {
        let matchValue, userId;
        return append_1((matchValue = patternInput_2[0], (matchValue != null) ? ((userId = (matchValue | 0), singleton_1(createElement(BanForm, {
            userId: userId,
            onClose: (didBan) => {
                if (didBan) {
                    const pr = dismiss(props.x.Id, props.dispatch);
                    pr.then();
                }
                setBanForm(void 0);
            },
        })))) : ((empty_1()))), delay(() => append_1(deleteForm ? singleton_1(deleteGroupForm({
            groupId: props.x.GroupId,
            onClose: (didDelete) => {
                if (didDelete) {
                    props.dispatch(new Message(7, props.x.GroupId));
                }
                setDeleteForm(false);
            },
        })) : empty_1(), delay(() => {
            let elems;
            return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
                borderBottom: (((1 + "px ") + "solid") + " ") + "#708090",
                marginBottom: 3,
            }], (elems = toList(delay(() => {
                let matchValue_1, imgId;
                return append_1((matchValue_1 = props.x.GroupImageId, (matchValue_1 != null) ? ((imgId = (matchValue_1 | 0), append_1(showGroupImg ? singleton_1(imageDisplay({
                    onClose: () => {
                        setShowGroupImg(false);
                    },
                    src: new Source(1, imgId),
                })) : empty_1(), delay(() => singleton_1(MuiHelpers_createElement(Avatar, [["alt", "Group img"], ["src", toText(printf("/api/groupimage/%i"))(imgId)], ["style", {
                    width: 40,
                    height: 40,
                    marginRight: 5,
                    cursor: "pointer",
                }], ["onClick", (_arg_1) => {
                    setShowGroupImg(!showGroupImg);
                }]])))))) : ((empty_1()))), delay(() => singleton_1(createElement("span", {
                    children: toText(printf("%s reported"))(props.x.GroupName),
                }))));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_1;
                return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                    borderBottom: (((1 + "px ") + "solid") + " ") + "#708090",
                    marginBottom: 3,
                }], (elems_1 = toList(delay(() => {
                    let matchValue_2, imgId_1;
                    return append_1((matchValue_2 = props.x.UserImageId, (matchValue_2 != null) ? ((imgId_1 = (matchValue_2 | 0), append_1(showUserImg ? singleton_1(imageDisplay({
                        onClose: () => {
                            setShowUserImg(false);
                        },
                        src: new Source(0, imgId_1),
                    })) : empty_1(), delay(() => singleton_1(MuiHelpers_createElement(Avatar, [["alt", "User img"], ["src", toText(printf("/api/profilepicture/%i"))(imgId_1)], ["style", {
                        width: 40,
                        height: 40,
                        marginRight: 5,
                        cursor: "pointer",
                    }], ["onClick", (_arg_3) => {
                        setShowUserImg(!showUserImg);
                    }]])))))) : ((empty_1()))), delay(() => singleton_1(createElement("span", {
                        children: toText(printf("reported by %s"))(props.x.Username),
                    }))));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                    let arg_4;
                    return append_1(singleton_1(createElement("span", {
                        style: {
                            fontSize: 1.2 + "rem",
                            fontWeight: "bold",
                        },
                        children: (arg_4 = toString_1(ReportReason_FromInt_Z524259A4(props.x.ReasonId)), toText(printf("%s"))(arg_4)),
                    })), delay(() => append_1(singleton_1(createElement("p", {
                        children: props.x.Reason,
                    })), delay(() => {
                        let elems_2;
                        return singleton_1(createElement("div", createObj(ofArray([["style", {
                            display: "flex",
                            flexWrap: "wrap",
                        }], (elems_2 = toList(delay(() => append_1(singleton_1(actionButton("Dismiss", verifyDismiss, (_arg_4) => {
                            if (!verifyDismiss) {
                                setVerifyDismiss(true);
                                setGroupImg(false);
                                setName(false);
                                setDeleteForm(false);
                            }
                            else {
                                const pr_1 = dismiss(props.x.Id, props.dispatch);
                                pr_1.then();
                            }
                        })), delay(() => {
                            let matchValue_3, imgId_2;
                            return append_1((matchValue_3 = props.x.GroupImageId, (matchValue_3 != null) ? ((imgId_2 = (matchValue_3 | 0), singleton_1(actionButton("Delete group image", verifyGroupImg, (_arg_5) => {
                                if (!verifyGroupImg) {
                                    setGroupImg(true);
                                    setVerifyDismiss(false);
                                    setName(false);
                                    setDeleteForm(false);
                                }
                                else {
                                    const pr_2 = deleteGroupImage(props.x.GroupId, imgId_2, props.dispatch);
                                    pr_2.then();
                                }
                            })))) : ((empty_1()))), delay(() => append_1(singleton_1(actionButton(toText(printf("Ban %s"))(props.x.Username), false, (_arg_6) => {
                                setVerifyDismiss(false);
                                setGroupImg(false);
                                setName(false);
                                setBanForm(props.x.UserId);
                                setDeleteForm(false);
                            })), delay(() => append_1(singleton_1(actionButton("Redact group name", verifyName, (_arg_7) => {
                                if (!verifyName) {
                                    setVerifyDismiss(false);
                                    setGroupImg(false);
                                    setName(true);
                                    setDeleteForm(false);
                                }
                                else {
                                    const pr_3 = redactName(props.x.GroupId, props.dispatch);
                                    pr_3.then();
                                }
                            })), delay(() => singleton_1(actionButton("Delete group", deleteForm, (_arg_8) => {
                                setVerifyDismiss(false);
                                setGroupImg(false);
                                setName(false);
                                setDeleteForm(true);
                            }))))))));
                        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
                    }))));
                }));
            }));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]);
}, void 0, uncurry(2, void 0), void 0, "report", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/Reports/GroupReports.fs", 170);

function view(model, dispatch) {
    let elems_1;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems_1 = toList(delay(() => append_1(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(8));
    })), delay(() => append_1(singleton_1(snackInfo(model.InfoMsg, () => {
        dispatch(new Message(9));
    })), delay(() => append_1(singleton_1(map((x) => {
        let elems;
        return createElement("div", createObj(ofArray([["key", int32ToString(x.Id)], (elems = [report({
            dispatch: dispatch,
            x: x,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, model.Reports)), delay(() => append_1((model.Reports.length === 0) ? singleton_1(createElement("span", {
        children: ["None"],
    })) : empty_1(), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "More"], ["onClick", (_arg_2) => {
        const pr = fetchReports(model.Row, dispatch);
        pr.then();
    }]])))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export const groupReports = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchReports(model_1.Row, dispatch);
        pr.then();
    }, []);
    return view(model_1, dispatch);
}, void 0, uncurry(2, void 0), void 0, "groupReports", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/Reports/GroupReports.fs", 426);

