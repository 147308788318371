import { StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { StyleCreator$1__create_4EAC9E1E } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { tail, head, isEmpty, cons, empty, ofArray, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { op_UnaryNegation_Int32 } from "./fable_modules/fable-library.3.7.22/Int32.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { SessionInfo$reflection } from "./Shared/ApiDataTypes.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { value as value_53, some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { int32_type, unit_type, equals, obj_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import * as react from "react";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { RouterModule_router, RouterModule_nav, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.10.0/Router.fs.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { DashboardInit } from "./Dashboard.js";
import { Login } from "./Login.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Home } from "./Home.js";
import { Reports } from "./Reports/Reports.js";
import { profileImages } from "./ProfileImages.js";
import { sessions } from "./Sessions.js";
import { Markets } from "./Markets.js";
import { Assets } from "./Assets.js";
import { InsiderTradeAdmin } from "./InsiderTrade.js";
import { ChangesInNumShares } from "./ShareChanges.js";
import { userSearch } from "./User/UserSearch.js";
import { User } from "./User/User.js";
import { groupSearch } from "./GroupSearch.js";
import { Group } from "./Group.js";
import { passwordForm } from "./PasswordForm.js";
import { Feedback } from "./Feedback.js";
import { ProhibitForm } from "./ProhibitedUsernames.js";
import { Route_$007CInt$007C_$007C } from "./fable_modules/Feliz.Router.3.10.0/Router.fs.js";
import { render } from "react-dom";

const useStyles = StyleImports_makeStyles_get((() => {
    let f1;
    const styles = StyleCreator$1_$ctor();
    f1 = ((theme) => {
        const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
        const appBar = StyleCreator$1__create_4EAC9E1E(styles, singleton(["backgroundColor", "#0d1321"]));
        const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", toText(printf("calc(100%% - %ipx)"))(150)], ["marginLeft", 150]]));
        const menuButton = StyleCreator$1__create_4EAC9E1E(styles, singleton(["marginRight", theme.spacing(2)]));
        const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
        const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 150 + "px"], ["flexShrink", 0]]));
        const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, singleton(["width", 150 + "px"]));
        const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["display", "flex"], ["alignItems", "center"], ["padding", theme.spacing(0, 1)], ["justifyContent", "flex-end"]]));
        return {
            appBar: appBar,
            appBarShift: appBarShift,
            content: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["flexGrow", 1], ["paddingTop", 64 + "px"], ["marginLeft", op_UnaryNegation_Int32(150)]])),
            contentShift: StyleCreator$1__create_4EAC9E1E(styles, singleton(["paddingTop", 64 + "px"])),
            drawer: drawer,
            drawerHeader: drawerHeader,
            drawerPaper: drawerPaper,
            hide: hide,
            menuButton: menuButton,
            root: root,
        };
    });
    return (arg_1) => {
        const o = f1(arg_1);
        return Object.assign({}, o);
    };
})());

export function checkLogin(callback) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(SessionInfo$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/session/validate", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(SessionInfo$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(SessionInfo$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 1) {
                const err = res.fields[0];
                let pattern_matching_result;
                if (err.tag === 2) {
                    if ((err.fields[0].status) === 401) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        callback(void 0);
                        return Promise.resolve();
                    }
                    case 1: {
                        console.log(some(err));
                        return Promise.resolve();
                    }
                }
            }
            else {
                callback(res.fields[0]);
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function fetchNumUnhandledShareChanges(callback) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(int32_type, void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/num-shares/num-unhandled", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(int32_type, caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(int32_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            callback(_arg_2);
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchNumUnhandledInsiderTrades(callback) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(int32_type, void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/insider-trade/num-unhandled", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(int32_type, caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(int32_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            callback(_arg_2);
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function drawSessionLoad() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        width: 100 + "vw",
        height: 100 + "vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }], (elems = [MuiHelpers_createElement(CircularProgress, [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Admin() {
    let elements_7, elems_10, elements_3, children_1, elements_1, props_3, elements_6, elems_5, elems_4, props_6, children_3, uit, usc, elements_4, elems_9;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setOpen = patternInput_1[1];
    const drawerOpen = patternInput_1[0];
    const s = useStyles();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSession = patternInput_2[1];
    const session = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const unhandledShareChanges = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const unhandledInsiderTrades = patternInput_4[0];
    useReact_useEffectOnce_3A5B6456(() => {
        checkLogin((res) => {
            if (res != null) {
                setSession(some(res));
                fetchNumUnhandledShareChanges((r) => {
                    if (r.tag === 0) {
                        patternInput_3[1](r.fields[0]);
                    }
                });
                fetchNumUnhandledInsiderTrades((r_1) => {
                    if (r_1.tag === 0) {
                        patternInput_4[1](r_1.fields[0]);
                    }
                });
            }
            else {
                setSession(some(void 0));
            }
        });
    });
    const menuItem = (url, title) => {
        let elements;
        return MuiHelpers_createElement(ListItem, [["button", true], ["key", url], ["onClick", (_arg_1) => {
            RouterModule_nav(singleton(url), 1, 1);
            setOpen(false);
        }], (elements = [MuiHelpers_createElement(ListItemText, [["primary", title]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
    };
    let pattern_matching_result;
    if (!isEmpty(currentUrl)) {
        if (head(currentUrl) === "dashboard") {
            if (isEmpty(tail(currentUrl))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return createElement(DashboardInit, null);
        }
        case 1: {
            if (session != null) {
                if (value_53(session) == null) {
                    return createElement(Login, null);
                }
                else {
                    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements_7 = singleton(createElement("div", createObj(ofArray([["className", s.root], (elems_10 = [MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", drawerOpen ? (`${s.appBar} ${s.appBarShift}`) : s.appBar], ["position", "fixed"], (elements_3 = [(children_1 = ofArray([MuiHelpers_createElement(IconButton, [["color", "inherit"], ["aria-label", "open drawer"], ["onClick", (_arg_2) => {
                        setOpen(!drawerOpen);
                    }], ["edge", "start"], ["className", (!drawerOpen) ? s.menuButton : (`${s.menuButton} ${s.hide}`)], (elements_1 = [(props_3 = {}, react.createElement(Menu, props_3))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]), MuiHelpers_createElement(Typography, [["children", "Aksje.io admin"]])]), MuiHelpers_createElement(Toolbar, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", drawerOpen], (elements_6 = [createElement("div", createObj(ofArray([["className", s.drawerHeader], (elems_5 = [MuiHelpers_createElement(IconButton, [["onClick", (_arg_3) => {
                        setOpen(false);
                    }], (elems_4 = [(props_6 = {}, react.createElement(ChevronRight, props_6))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), MuiHelpers_createElement(Divider, []), (children_3 = ofArray([menuItem("", "Home"), menuItem("dashboard", "Dashboard"), menuItem("reports", "Reports"), menuItem("markets", "Markets"), menuItem("assets", "Assets"), menuItem("insider-trades", (unhandledInsiderTrades != null) ? ((uit = (unhandledInsiderTrades | 0), `Insider trades (${uit})`)) : "Insider trades"), menuItem("share-changes", (unhandledShareChanges != null) ? ((usc = (unhandledShareChanges | 0), `Share changes (${usc})`)) : "Share changes"), menuItem("usersearch", "Find user"), menuItem("prohibited-usernames", "Prohibited usernames"), menuItem("groupsearch", "Find group"), menuItem("profileimages", "Profile images"), menuItem("automod", "AutoModerator"), menuItem("sessions", "Admin sessions"), menuItem("change-password", "Change password"), menuItem("feedback", "Feedback"), MuiHelpers_createElement(Divider, []), MuiHelpers_createElement(ListItem, [["key", "logout"], ["href", "/logout"], (elements_4 = [MuiHelpers_createElement(ListItemText, [["primary", "Logout"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_6))])]), createElement("main", createObj(ofArray([["className", drawerOpen ? (`${s.content} ${s.contentShift}`) : s.content], (elems_9 = toList(delay(() => append(singleton_1(createElement("div", {
                        className: s.drawerHeader,
                    })), delay(() => {
                        const matchValue = currentUrl;
                        let pattern_matching_result_1, userId;
                        if (!isEmpty(matchValue)) {
                            if (head(matchValue) === "reports") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 1;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "profileimages") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 2;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "sessions") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 3;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "markets") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 4;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "assets") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 5;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "insider-trades") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 6;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "share-changes") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 7;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "usersearch") {
                                if (isEmpty(tail(matchValue))) {
                                    pattern_matching_result_1 = 8;
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else if (head(matchValue) === "user") {
                                if (!isEmpty(tail(matchValue))) {
                                    const activePatternResult = Route_$007CInt$007C_$007C(head(tail(matchValue)));
                                    if (activePatternResult != null) {
                                        if (isEmpty(tail(tail(matchValue)))) {
                                            pattern_matching_result_1 = 9;
                                            userId = activePatternResult;
                                        }
                                        else {
                                            pattern_matching_result_1 = 10;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_1 = 10;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 10;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 10;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 0;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                return singleton_1(createElement(Home, null));
                            }
                            case 1: {
                                return singleton_1(createElement(Reports, null));
                            }
                            case 2: {
                                return singleton_1(profileImages());
                            }
                            case 3: {
                                return singleton_1(sessions());
                            }
                            case 4: {
                                return singleton_1(createElement(Markets, null));
                            }
                            case 5: {
                                return singleton_1(createElement(Assets, null));
                            }
                            case 6: {
                                return singleton_1(createElement(InsiderTradeAdmin, null));
                            }
                            case 7: {
                                return singleton_1(createElement(ChangesInNumShares, null));
                            }
                            case 8: {
                                return singleton_1(userSearch());
                            }
                            case 9: {
                                return singleton_1(createElement(User, {
                                    userId: userId,
                                }));
                            }
                            case 10: {
                                let pattern_matching_result_2, groupId;
                                if (!isEmpty(matchValue)) {
                                    if (head(matchValue) === "groupsearch") {
                                        if (isEmpty(tail(matchValue))) {
                                            pattern_matching_result_2 = 0;
                                        }
                                        else {
                                            pattern_matching_result_2 = 2;
                                        }
                                    }
                                    else if (head(matchValue) === "group") {
                                        if (!isEmpty(tail(matchValue))) {
                                            const activePatternResult_1 = Route_$007CInt$007C_$007C(head(tail(matchValue)));
                                            if (activePatternResult_1 != null) {
                                                if (isEmpty(tail(tail(matchValue)))) {
                                                    pattern_matching_result_2 = 1;
                                                    groupId = activePatternResult_1;
                                                }
                                                else {
                                                    pattern_matching_result_2 = 2;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_2 = 2;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_2 = 2;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 2;
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 2;
                                }
                                switch (pattern_matching_result_2) {
                                    case 0: {
                                        return singleton_1(groupSearch());
                                    }
                                    case 1: {
                                        return singleton_1(createElement(Group, {
                                            groupId: groupId,
                                        }));
                                    }
                                    case 2: {
                                        let pattern_matching_result_3;
                                        if (!isEmpty(matchValue)) {
                                            if (head(matchValue) === "change-password") {
                                                if (isEmpty(tail(matchValue))) {
                                                    pattern_matching_result_3 = 0;
                                                }
                                                else {
                                                    pattern_matching_result_3 = 3;
                                                }
                                            }
                                            else if (head(matchValue) === "feedback") {
                                                if (isEmpty(tail(matchValue))) {
                                                    pattern_matching_result_3 = 1;
                                                }
                                                else {
                                                    pattern_matching_result_3 = 3;
                                                }
                                            }
                                            else if (head(matchValue) === "prohibited-usernames") {
                                                if (isEmpty(tail(matchValue))) {
                                                    pattern_matching_result_3 = 2;
                                                }
                                                else {
                                                    pattern_matching_result_3 = 3;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_3 = 3;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_3 = 3;
                                        }
                                        switch (pattern_matching_result_3) {
                                            case 0: {
                                                return singleton_1(passwordForm());
                                            }
                                            case 1: {
                                                return singleton_1(createElement(Feedback, null));
                                            }
                                            case 2: {
                                                return singleton_1(createElement(ProhibitForm, null));
                                            }
                                            case 3: {
                                                return singleton_1(createElement("h1", {
                                                    children: ["Not found"],
                                                }));
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))), ["application", react.createElement(react.Fragment, {}, ...elements_7)])])));
                }
            }
            else {
                return drawSessionLoad();
            }
        }
    }
}

render(createElement(Admin, null), document.getElementById("admin-app"));

