import { parse, toString } from "./fable_modules/fable-library.3.7.22/Date.js";

export function toISO8601(dt) {
    return toString(dt, "yyyy-MM-dd HH:mmZ");
}

export function utcDateToLocalDateTime(x) {
    try {
        let copyOfStruct = parse(x);
        return toString(copyOfStruct, "dd/MM/yyyy HH:mm");
    }
    catch (matchValue) {
        return x;
    }
}

export function trySetInputValue(fieldId, newVal) {
    try {
        const entry = window.document.getElementById(fieldId);
        entry.value = newVal;
    }
    catch (matchValue) {
    }
}

