import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { GenericResponse$reflection, Feedback$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, option_type, string_type, bool_type, int32_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { map as map_1, maxBy, append } from "./fable_modules/fable-library.3.7.22/Array.js";
import { createObj, uncurry, comparePrimitives } from "./fable_modules/fable-library.3.7.22/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createElement } from "react";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { snackError } from "./ViewHelpers.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";

class Model extends Record {
    constructor(Entries, LastId, IsMore, ErrorMsg) {
        super();
        this.Entries = Entries;
        this.LastId = (LastId | 0);
        this.IsMore = IsMore;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Feedback.Model", [], Model, () => [["Entries", array_type(Feedback$reflection())], ["LastId", int32_type], ["IsMore", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadResponse", "RemoveEntry", "SetErrorMsg", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Feedback.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Feedback$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Feedback$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", string_type]], []]);
}

function init() {
    return new Model([], 0, true, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.Entries.filter((e) => (e.Id !== msg.fields[0])), model.LastId, model.IsMore, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.Entries, model.LastId, model.IsMore, msg.fields[0]);
        }
        case 3: {
            return new Model(model.Entries, model.LastId, model.IsMore, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Entries, model.LastId, model.IsMore, "Could not load feedback.");
            }
            else {
                const x = res.fields[0];
                const y = append(x, model.Entries);
                return new Model(y, (y.length > 0) ? maxBy((z) => z.Id, y, {
                    Compare: comparePrimitives,
                }).Id : 0, x.length > 0, model.ErrorMsg);
            }
        }
    }
}

function loadMore(lastId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("/api/feedback?lid=%i"))(lastId);
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Feedback$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Feedback$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Feedback$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function dismiss(fId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(fId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/feedback", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 1) {
                dispatch(new Message(2, "Could not dismiss"));
                return Promise.resolve();
            }
            else {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(1, fId));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(2, x_1.Message));
                    return Promise.resolve();
                }
            }
        });
    }));
    pr_1.then();
}

function view(model, dispatch) {
    let elems_4;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 20,
    }], (elems_4 = toList(delay(() => append_1(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(3));
    })), delay(() => append_1(model.IsMore ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Load more"], ["onClick", (_arg_1) => {
        loadMore(model.LastId, dispatch);
    }]])) : empty_1(), delay(() => {
        let elems_3;
        return singleton_1(createElement("div", createObj(ofArray([["style", {
            marginTop: 10,
            marginBottom: 10,
            width: 100 + "%",
            maxWidth: 500,
        }], (elems_3 = [map_1((x) => {
            let elems_2, elems, value_32, children, elems_1;
            return MuiHelpers_createElement(Paper, [["style", {
                display: "flex",
                flexDirection: "column",
                padding: 10,
                marginBottom: 10,
            }], ["elevation", 2], (elems_2 = [createElement("div", createObj(ofArray([["style", {
                display: "flex",
            }], (elems = [(value_32 = toText(printf("%s submitted %s"))(x.User.Username)(x.Submitted), createElement("span", {
                children: [value_32],
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), (children = singleton(createElement("p", {
                style: {
                    whiteSpace: "pre-wrap",
                },
                children: x.Content,
            })), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "flex-end",
            }], (elems_1 = [MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Dismiss"], ["onClick", (_arg_2) => {
                dismiss(x.Id, dispatch);
            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]);
        }, model.Entries)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function Feedback() {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        loadMore(model_1.LastId, dispatch);
    });
    return view(model_1, dispatch);
}

