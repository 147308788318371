import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, union_type, record_type, bool_type, option_type, string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { LoginRequest$reflection, LoginRequest, GenericResponse$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { StyleCreator$1__create_4EAC9E1E } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Container from "@material-ui/core/Container";
import { createElement } from "react";
import TextField from "@material-ui/core/TextField";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import Button from "@material-ui/core/Button";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";

class Model extends Record {
    constructor(Username, Password, ErrorMsg, Processing) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.ErrorMsg = ErrorMsg;
        this.Processing = Processing;
    }
}

function Model$reflection() {
    return record_type("Login.Model", [], Model, () => [["Username", string_type], ["Password", string_type], ["ErrorMsg", option_type(string_type)], ["Processing", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateUsername", "UpdatePassword", "TryLogin", "LoginResponse"];
    }
}

function Message$reflection() {
    return union_type("Login.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init() {
    return new Model("", "", void 0, false);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.Username, msg.fields[0], model.ErrorMsg, model.Processing);
        }
        case 2: {
            if ((model.Username.length > 0) && (model.Password.length > 0)) {
                return new Model(model.Username, model.Password, model.ErrorMsg, true);
            }
            else {
                return model;
            }
        }
        case 3: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Username, model.Password, "Something went wrong. Could not perform login.", false);
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.Result === "success") {
                    window.location.reload(true);
                    return model;
                }
                else {
                    return new Model(model.Username, model.Password, x_2.Message, false);
                }
            }
        }
        default: {
            return new Model(msg.fields[0], model.Password, model.ErrorMsg, model.Processing);
        }
    }
}

function tryLogin(username, password, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(2));
        if ((username.length > 0) && (password.length > 0)) {
            const req = new LoginRequest(username, password);
            return ((body = Auto_generateBoxedEncoder_Z20B7B430(LoginRequest$reflection(), void 0, void 0, void 0)(req), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/session/login", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_2) => {
                dispatch(new Message(3, _arg_2));
                return Promise.resolve();
            });
        }
        else {
            return Promise.resolve();
        }
    }));
    pr_1.then();
}

export const useStyles = StyleImports_makeStyles_get((() => {
    let f1;
    const styles = StyleCreator$1_$ctor();
    f1 = ((theme) => {
        const paper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginTop", theme.spacing(8)], ["display", "flex"], ["flexDirection", "column"], ["alignItems", "center"], ["justifyContent", "center"], ["minHeight", 70 + "vh"]]));
        const progressBar = StyleCreator$1__create_4EAC9E1E(styles, singleton(["margin", theme.spacing(1)]));
        return {
            avatar: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["margin", theme.spacing(1)], ["backgroundColor", theme.palette.primary.main]])),
            form: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginTop", theme.spacing(1)], ["display", "flex"], ["flexDirection", "column"], ["width", 50 + "vw"], ["maxWidth", 300 + "px"]])),
            paper: paper,
            progressBar: progressBar,
            submit: StyleCreator$1__create_4EAC9E1E(styles, singleton(["margin", theme.spacing(3, 0, 2)])),
            text: StyleCreator$1__create_4EAC9E1E(styles, singleton(["marginTop", 10])),
        };
    });
    return (arg) => {
        const o = f1(arg);
        return Object.assign({}, o);
    };
})());

export function Login() {
    let elements, elems_1, elems;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const s = useStyles();
    return MuiHelpers_createElement(Container, [(elements = [createElement("div", createObj(ofArray([["className", s.paper], (elems_1 = [createElement("form", createObj(ofArray([["className", s.form], (elems = [MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.text]), delay(() => append(singleton_1(["type", "text"]), delay(() => append(singleton_1(["label", "Username"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["onChange", (e) => {
        dispatch(new Message(0, Browser_Types_Event__Event_get_Value(e)));
    }]), delay(() => append(singleton_1(["autoFocus", true]), delay(() => {
        if (model_1.ErrorMsg != null) {
            return singleton_1(["error", true]);
        }
        else {
            return empty_1();
        }
    }))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.text]), delay(() => append(singleton_1(["type", "password"]), delay(() => append(singleton_1(["label", "Password"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
        dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e_1)));
    }]), delay(() => append(singleton_1(["autoComplete", "current-password"]), delay(() => {
        const matchValue_1 = model_1.ErrorMsg;
        if (matchValue_1 != null) {
            const errMsg = matchValue_1;
            return append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", errMsg])));
        }
        else {
            return empty_1();
        }
    }))))))))))))))))), MuiHelpers_createElement(Button, [["className", s.submit], ["type", "submit"], ["fullWidth", true], ["variant", "contained"], ["color", "primary"], ["children", "Sign In"], ["disabled", model_1.Processing], ["onClick", (e_2) => {
        e_2.preventDefault();
        if (!model_1.Processing) {
            tryLogin(model_1.Username, model_1.Password, dispatch);
        }
    }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
}

