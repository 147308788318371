
export const DashboardTokenId = "dashboardtoken";

export function tryGetToken() {
    try {
        const x = localStorage.getItem(DashboardTokenId);
        return (!(x == null)) ? x : (void 0);
    }
    catch (matchValue) {
        return void 0;
    }
}

export function setToken(tkn) {
    try {
        localStorage.setItem(DashboardTokenId, tkn);
    }
    catch (matchValue) {
    }
}

export function deleteToken() {
    try {
        localStorage.removeItem(DashboardTokenId);
    }
    catch (matchValue) {
    }
}

