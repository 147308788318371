import { Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { union_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";

export class ReportReason extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Spam", "Undocumented", "RacistContent", "SexualContent", "Picture", "GroupRules", "Other"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Undocumented and/or false claims";
            }
            case 2: {
                return "Racism/hate speech";
            }
            case 3: {
                return "Sexual content";
            }
            case 4: {
                return "Improper/sexual/hateful picture";
            }
            case 5: {
                return "Breach of group rules";
            }
            case 6: {
                return "Other";
            }
            default: {
                return "Spam/irrelevant content";
            }
        }
    }
}

export function ReportReason$reflection() {
    return union_type("Shared.ReportReason", [], ReportReason, () => [[], [], [], [], [], [], []]);
}

export function ReportReason__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 1;
        }
        case 2: {
            return 2;
        }
        case 3: {
            return 3;
        }
        case 4: {
            return 4;
        }
        case 5: {
            return 5;
        }
        case 6: {
            return 6;
        }
        default: {
            return 0;
        }
    }
}

export function ReportReason_FromInt_Z524259A4(x) {
    switch (x) {
        case 0: {
            return new ReportReason(0);
        }
        case 1: {
            return new ReportReason(1);
        }
        case 2: {
            return new ReportReason(2);
        }
        case 3: {
            return new ReportReason(3);
        }
        case 4: {
            return new ReportReason(4);
        }
        case 5: {
            return new ReportReason(5);
        }
        default: {
            return new ReportReason(6);
        }
    }
}

export function ReportReason_AsArray() {
    return [new ReportReason(0), new ReportReason(1), new ReportReason(2), new ReportReason(3), new ReportReason(4), new ReportReason(6)];
}

export function ReportReason_AsArrayGroup() {
    return [new ReportReason(0), new ReportReason(1), new ReportReason(2), new ReportReason(3), new ReportReason(4), new ReportReason(5), new ReportReason(6)];
}

