import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { GenericResponse$reflection, InsiderTrade, TradeExchangeInfo, InsiderTrade$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { int32ToString, createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { empty as empty_1, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { parse } from "./fable_modules/fable-library.3.7.22/Int32.js";
import { parse as parse_1 } from "./fable_modules/fable-library.3.7.22/Double.js";
import { replace } from "./fable_modules/fable-library.3.7.22/String.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import { snackError, loadingIndicatorSmall } from "./ViewHelpers.js";
import Button from "@material-ui/core/Button";
import { map as map_1 } from "./fable_modules/fable-library.3.7.22/Array.js";
import Skeleton from "@material-ui/lab/Skeleton";

class Model extends Record {
    constructor(Entries, ErrorMsg) {
        super();
        this.Entries = Entries;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("InsiderTrade.Model", [], Model, () => [["Entries", option_type(array_type(InsiderTrade$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EntriesResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("InsiderTrade.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(InsiderTrade$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(InsiderTrade$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init() {
    return new Model(void 0, void 0);
}

function update(model, msg) {
    if (msg.tag === 1) {
        return new Model(model.Entries, void 0);
    }
    else {
        const res = msg.fields[0];
        if (res.tag === 1) {
            return new Model(model.Entries, "Could not load insider trades. Please try again later.");
        }
        else {
            return new Model(res.fields[0], model.ErrorMsg);
        }
    }
}

function fetchEntries(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(InsiderTrade$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/insider-trade/updates", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(InsiderTrade$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(InsiderTrade$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function DrawEntry(x) {
    let matchValue, matchValue_1, elems_6;
    const patternInput = useFeliz_React__React_useState_Static_1505(x.Insider);
    const insider = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(x.NumShares);
    const numShares = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validNumShares = patternInput_2[0];
    const setValidNumShares = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(x.AvgPrice != null);
    const hasAvgPrice = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(x.AvgPrice);
    const avgPrice = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(true);
    const validAvgPrice = patternInput_5[0];
    const setValidAvgPrice = patternInput_5[1];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(x.NewTotal != null);
    const hasNewTotal = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(x.NewTotal);
    const newTotal = patternInput_7[0];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(true);
    const validNewTotal = patternInput_8[0];
    const setValidNewTotal = patternInput_8[1];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(x.Verified);
    const confirmed = patternInput_9[0];
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(x.Removed);
    const removed = patternInput_10[0];
    const patternInput_11 = useFeliz_React__React_useState_Static_1505((matchValue = x.Exchanged, (matchValue != null) ? matchValue.AvgPrice : x.AvgPrice));
    const exchanged = patternInput_11[0];
    const patternInput_12 = useFeliz_React__React_useState_Static_1505((matchValue_1 = x.Exchanged, (matchValue_1 != null) ? matchValue_1.Currency : "USD"));
    const exchangeCurrency = patternInput_12[0];
    const patternInput_13 = useFeliz_React__React_useState_Static_1505(true);
    const validExchanged = patternInput_13[0];
    const setValidExchanged = patternInput_13[1];
    const patternInput_14 = useFeliz_React__React_useState_Static_1505(x.Exchanged != null);
    const setIsExchanged = patternInput_14[1];
    const isExchanged = patternInput_14[0];
    const patternInput_15 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_15[1];
    const patternInput_16 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_16[1];
    const patternInput_17 = useFeliz_React__React_useState_Static_1505(false);
    const setDidChange = patternInput_17[1];
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        width: 100 + "%",
        maxWidth: 400,
        border: (((1 + "px ") + "solid") + " ") + "#dedede",
        borderRadius: 5,
        padding: 10,
        backgroundColor: "#FFFFFF",
        gap: 20 + "px ",
    }], (elems_6 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontWeight: "bold",
        }], (elems = [createElement("span", {
            children: `${x.Asset} ${x.Market}`,
        }), createElement("span", {
            children: [x.Date],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_4, elems_1, a_1, elems_2, elems_3, e_9, children;
            return append(singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                gap: 10 + "px ",
            }], (elems_4 = [MuiHelpers_createElement(TextField, [["id", `${x.Id}-insider`], ["defaultValue", insider], ["onChange", (e_3) => {
                patternInput[1](Browser_Types_Event__Event_get_Value(e_3));
                setDidChange(true);
            }], ["size", "small"], ["variant", "outlined"], ["label", "Insider"], ["disabled", confirmed]]), MuiHelpers_createElement(TextField, [["id", `${x.Id}-num-shares`], ["defaultValue", int32ToString(numShares)], ["onChange", (e_4) => {
                const n = Browser_Types_Event__Event_get_Value(e_4);
                try {
                    patternInput_1[1](parse(n, 511, false, 32));
                    setValidNumShares(true);
                    setDidChange(true);
                }
                catch (matchValue_3) {
                    setValidNumShares(false);
                }
            }], ["size", "small"], ["variant", "outlined"], ["label", "Num shares"], ["error", !validNumShares], ["disabled", confirmed]]), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
            }], (elems_1 = [MuiHelpers_createElement(TextField, [["id", `${x.Id}-avg-price`], ["defaultValue", (avgPrice == null) ? "" : ((a_1 = avgPrice, a_1.toString()))], ["onChange", (e_5) => {
                const n_1 = Browser_Types_Event__Event_get_Value(e_5);
                try {
                    const asFloat = parse_1(replace(replace(n_1, ",", "."), " ", ""));
                    if (asFloat >= 0) {
                        patternInput_4[1](asFloat);
                        setValidAvgPrice(true);
                        setDidChange(true);
                    }
                    else {
                        setValidAvgPrice(false);
                    }
                }
                catch (matchValue_4) {
                    setValidAvgPrice(false);
                }
            }], ["size", "small"], ["variant", "outlined"], ["label", "Avg. price"], ["error", !validAvgPrice], ["disabled", (!hasAvgPrice) ? true : confirmed]]), MuiHelpers_createElement(Checkbox, [["checked", hasAvgPrice], ["onChange", (e_6) => {
                patternInput_3[1](Browser_Types_Event__Event_get_Checked(e_6));
                setDidChange(true);
            }], ["size", "small"], ["disabled", confirmed]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
            }], (elems_2 = [MuiHelpers_createElement(TextField, [["id", `${x.Id}-new-total`], ["defaultValue", (newTotal == null) ? "" : int32ToString(newTotal)], ["onChange", (e_7) => {
                const n_2 = replace(replace(Browser_Types_Event__Event_get_Value(e_7), ",", "."), " ", "");
                try {
                    const asInt_1 = parse(n_2, 511, false, 32) | 0;
                    if (asInt_1 >= 0) {
                        patternInput_7[1](asInt_1);
                        setValidNewTotal(true);
                        setDidChange(true);
                    }
                    else {
                        setValidNewTotal(false);
                    }
                }
                catch (matchValue_5) {
                    setValidNewTotal(false);
                }
            }], ["size", "small"], ["variant", "outlined"], ["label", "New total"], ["error", !validNewTotal], ["disabled", (!hasNewTotal) ? true : confirmed]]), MuiHelpers_createElement(Checkbox, [["checked", hasNewTotal], ["onChange", (e_8) => {
                patternInput_6[1](Browser_Types_Event__Event_get_Checked(e_8));
                setDidChange(true);
            }], ["size", "small"], ["disabled", confirmed]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
            }], (elems_3 = [MuiHelpers_createElement(TextField, [["id", `${x.Id}-exchange-price`], ["defaultValue", (exchanged == null) ? "" : ((e_9 = exchanged, e_9.toString()))], ["onChange", (e_10) => {
                const n_3 = Browser_Types_Event__Event_get_Value(e_10);
                try {
                    const asFloat_1 = parse_1(replace(replace(n_3, ",", "."), " ", ""));
                    if (asFloat_1 >= 0) {
                        patternInput_11[1](asFloat_1);
                        setValidExchanged(true);
                        setIsExchanged(true);
                    }
                    else {
                        setValidExchanged(false);
                    }
                }
                catch (matchValue_6) {
                    setValidExchanged(false);
                }
            }], ["size", "small"], ["variant", "outlined"], ["label", "Exchanged from"], ["error", !validExchanged], ["disabled", (!isExchanged) ? true : confirmed]]), MuiHelpers_createElement(TextField, [["id", `${x.Id}-exchange-currency`], ["defaultValue", exchangeCurrency], ["onChange", (e_11) => {
                const n_4 = Browser_Types_Event__Event_get_Value(e_11);
                patternInput_12[1](n_4.trim().toLocaleUpperCase());
            }], ["size", "small"], ["variant", "outlined"], ["label", "Curr."], ["disabled", (!isExchanged) ? true : confirmed], ["style", {
                maxWidth: 70,
            }]]), MuiHelpers_createElement(Checkbox, [["checked", isExchanged], ["onChange", (e_12) => {
                setIsExchanged(Browser_Types_Event__Event_get_Checked(e_12));
                setDidChange(true);
            }], ["size", "small"], ["disabled", confirmed]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), (children = singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Removed"], ["onChange", (e_13) => {
                const b_3 = Browser_Types_Event__Event_get_Checked(e_13);
                setDidChange(true);
                patternInput_10[1](b_3);
            }], ["disabled", confirmed], ["control", MuiHelpers_createElement(Checkbox, [["checked", removed]])]])), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                let matchValue_7;
                return append((matchValue_7 = patternInput_16[0], (matchValue_7 != null) ? singleton(MuiHelpers_createElement(Alert, [["variant", "outlined"], ["severity", "error"], ["onClose", (_arg_3) => {
                    setErrMsg(void 0);
                }], ["children", matchValue_7]])) : ((empty_1()))), delay(() => {
                    let elems_5;
                    return singleton(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }], (elems_5 = toList(delay(() => append(singleton(createElement("a", {
                        href: x.SourceUrl,
                        target: "_blank",
                        children: "Source",
                    })), delay(() => ((!confirmed) ? (patternInput_15[0] ? singleton(loadingIndicatorSmall()) : singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "contained"], ["disabled", (((insider.trim().length === 0) ? true : (!validNumShares)) ? true : ((!validAvgPrice) && hasAvgPrice)) ? true : ((!validNumShares) && hasNewTotal)], ["children", "Confirm"], ["onClick", (_arg_4) => {
                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let body, decoder, data_1, caseStrategy_3, extra_3;
                            if (((((insider.trim().length > 0) && validNumShares) && (validAvgPrice ? true : (!hasAvgPrice))) && (validNewTotal ? true : (!hasNewTotal))) && (validExchanged ? true : (!isExchanged))) {
                                setProcessing(true);
                                const req = new InsiderTrade(x.Id, x.Date, x.AssetId, x.Asset, x.Market, insider, numShares, (!hasAvgPrice) ? (void 0) : avgPrice, (!hasNewTotal) ? (void 0) : newTotal, x.Verified, (!isExchanged) ? (void 0) : ((exchanged != null) ? (new TradeExchangeInfo(exchanged, exchangeCurrency)) : (void 0)), removed, x.SourceUrl);
                                const requestPath = `/api/insider-trade/confirm?altered=${patternInput_17[0]}`;
                                return ((body = Auto_generateBoxedEncoder_Z20B7B430(InsiderTrade$reflection(), void 0, void 0, void 0)(req), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                                    let properties_2, headers_2;
                                    try {
                                        const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                                            let response_1, decoder_1_1;
                                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                                let matchValue_2;
                                                return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue_2 = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue_2.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue_2.fields[0]))) : (new FSharpResult$2(0, matchValue_2.fields[0])))));
                                            })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                        }))));
                                        return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                                    }
                                    catch (exn) {
                                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                                    }
                                })())))))).then((_arg_2) => {
                                    const res = _arg_2;
                                    if (res.tag === 0) {
                                        const x_2 = res.fields[0];
                                        if (x_2.Result === "success") {
                                            setProcessing(false);
                                            patternInput_9[1](true);
                                            return Promise.resolve();
                                        }
                                        else {
                                            setProcessing(false);
                                            setErrMsg(x_2.Message);
                                            return Promise.resolve();
                                        }
                                    }
                                    else {
                                        setProcessing(false);
                                        setErrMsg("Could not confirm insider trade. Please try again later");
                                        return Promise.resolve();
                                    }
                                });
                            }
                            else {
                                return Promise.resolve();
                            }
                        }));
                        pr_1.then();
                    }]]))) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

export function InsiderTradeAdmin() {
    let elems_1, elems;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchEntries(dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        padding: 1 + "vw",
    }], (elems_1 = [snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(1));
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20 + "px ",
        width: 100 + "%",
    }], (elems = toList(delay(() => {
        const matchValue = model_1.Entries;
        if (matchValue != null) {
            return singleton(map_1((x) => createElement("div", {
                style: {
                    width: 100 + "%",
                    maxWidth: 400,
                },
                key: `insider-trade-${x.Id}`,
                children: createElement(DrawEntry, x),
            }), matchValue));
        }
        else {
            const loadingIndicator = () => MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                width: 100 + "%",
                maxWidth: 400,
                height: 70,
            }]]);
            return append(singleton(loadingIndicator()), delay(() => append(singleton(loadingIndicator()), delay(() => singleton(loadingIndicator())))));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

