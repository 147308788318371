import { Union } from "./fable_modules/fable-library.3.7.22/Types.js";
import { union_type, int32_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import { printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";

export class Source extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserImage", "GroupImage", "User", "Group"];
    }
}

export function Source$reflection() {
    return union_type("ImageDisplay.Source", [], Source, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export const imageDisplay = FunctionComponent_Of_Z5A158BBF((props) => {
    let elements_2, children, matchValue, children_1;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        props.onClose();
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Image"]]), (children = singleton(createElement("img", {
        src: (matchValue = props.src, (matchValue.tag === 0) ? toText(printf("/api/profilepicture/%i"))(matchValue.fields[0]) : ((matchValue.tag === 1) ? toText(printf("/api/groupimage/%i"))(matchValue.fields[0]) : "")),
        style: {
            width: 350,
            height: 350,
        },
    })), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Close"], ["onClick", (_arg_2) => {
        props.onClose();
    }]])), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}, void 0, uncurry(2, void 0), void 0, "imageDisplay", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/ImageDisplay.fs", 13);

