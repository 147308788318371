import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { snackError } from "./ViewHelpers.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createElement } from "react";
import { uncurry, createObj } from "./fable_modules/fable-library.3.7.22/Util.js";
import { empty, append, singleton, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { cons, empty as empty_1, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.7.22/List.js";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { unit_type, equals, obj_type, tuple_type, int32_type, string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { GenericResponse$reflection } from "./Shared/ApiDataTypes.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import DialogActions from "@material-ui/core/DialogActions";

export const deleteGroupForm = FunctionComponent_Of_Z5A158BBF((props) => {
    let elements_2, children, elems, children_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const deleted = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const pw = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_2[1];
    const children_2 = ofArray([snackError(patternInput_2[0], () => {
        setErrMsg(void 0);
    }), MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_5, v) => {
        props.onClose(deleted);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Delete group"]]), (children = singleton_1(createElement("div", createObj(ofArray([["style", {
        minWidth: 350,
        padding: 20,
    }], (elems = toList(delay(() => ((!deleted) ? singleton(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["autoFocus", true]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["defaultValue", pw]), delay(() => append(singleton(["onChange", (e) => {
        patternInput_1[1](Browser_Types_Event__Event_get_Value(e));
    }]), delay(() => append(singleton(["label", "Password"]), delay(() => append(singleton(["required", true]), delay(() => append(singleton(["type", "password"]), delay(() => append(singleton(["variant", "outlined"]), delay(() => ((pw.length === 0) ? singleton(["error", true]) : empty()))))))))))))))))))))) : singleton(createElement("span", {
        children: "Group has been deleted",
    }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Cancel"], ["onClick", (_arg_6) => {
        props.onClose(deleted);
    }]])), delay(() => ((!deleted) ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["disabled", pw.length === 0], ["children", "Delete"], ["onClick", (_arg_7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body, decoder, data_1, caseStrategy_3, extra_3;
            return ((body = Auto_generateBoxedEncoder_Z20B7B430(tuple_type(string_type, int32_type), void 0, void 0, void 0)([pw, props.groupId]), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty_1()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/group", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 1) {
                    const err = res.fields[0];
                    setErrMsg((err.tag === 2) ? (((err.fields[0].status) === 401) ? "Authentication failed" : (((err.fields[0].status) === 404) ? "Could not find group to delete" : "Could not delete group.")) : "Could not delete group.");
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    if (x_1.Result === "success") {
                        patternInput[1](true);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(x_1.Message);
                        return Promise.resolve();
                    }
                }
            });
        }));
        pr_1.then();
    }]])) : empty()))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}, void 0, uncurry(2, void 0), void 0, "deleteGroupForm", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/DeleteGroupForm.fs", 8);

