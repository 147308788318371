import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, tuple_type, union_type, record_type, bool_type, option_type, string_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { GenericResponse$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { StyleCreator$1__create_4EAC9E1E } from "./fable_modules/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { createElement } from "react";
import { pageHeader, snackInfo, snackError } from "./ViewHelpers.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import Button from "@material-ui/core/Button";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";
import { useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";

class Model extends Record {
    constructor(OldPassword, NewPassword, RepeatPassword, ErrorMsg, InfoMsg, Updated) {
        super();
        this.OldPassword = OldPassword;
        this.NewPassword = NewPassword;
        this.RepeatPassword = RepeatPassword;
        this.ErrorMsg = ErrorMsg;
        this.InfoMsg = InfoMsg;
        this.Updated = Updated;
    }
}

function Model$reflection() {
    return record_type("PasswordForm.Model", [], Model, () => [["OldPassword", string_type], ["NewPassword", string_type], ["RepeatPassword", string_type], ["ErrorMsg", option_type(string_type)], ["InfoMsg", option_type(string_type)], ["Updated", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateOld", "UpdateNew", "UpdateRepeat", "UpdateResponse", "SetError", "DismissError", "DismissInfo"];
    }
}

function Message$reflection() {
    return union_type("PasswordForm.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [], []]);
}

function init() {
    return new Model("", "", "", void 0, void 0, false);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.OldPassword, msg.fields[0], model.RepeatPassword, model.ErrorMsg, model.InfoMsg, model.Updated);
        }
        case 2: {
            return new Model(model.OldPassword, model.NewPassword, msg.fields[0], model.ErrorMsg, model.InfoMsg, model.Updated);
        }
        case 3: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, "Could not update password", model.InfoMsg, model.Updated);
            }
            else {
                const x_3 = res.fields[0];
                if (x_3.Result === "success") {
                    return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, model.ErrorMsg, "Password updated", true);
                }
                else {
                    return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, x_3.Message, model.InfoMsg, model.Updated);
                }
            }
        }
        case 4: {
            return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, msg.fields[0], model.InfoMsg, model.Updated);
        }
        case 5: {
            return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, void 0, model.InfoMsg, model.Updated);
        }
        case 6: {
            return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, model.ErrorMsg, void 0, model.Updated);
        }
        default: {
            return new Model(msg.fields[0], model.NewPassword, model.RepeatPassword, model.ErrorMsg, model.InfoMsg, model.Updated);
        }
    }
}

function updatePassword(old, new$0027, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(tuple_type(string_type, string_type), void 0, void 0, void 0)([old, new$0027]), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/admin/change-password", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(3, _arg_2));
            return Promise.resolve();
        });
    }));
}

const useStyles = StyleImports_makeStyles_get((() => {
    let f1;
    const styles = StyleCreator$1_$ctor();
    f1 = ((theme) => {
        const root = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["display", "flex"], ["flexDirection", "column"], ["alignItems", "center"], ["minHeight", 400], ["justifyContent", "center"]]));
        return {
            box: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 400], ["padding", 20], ["display", "flex"], ["flexDirection", "column"], ["alignItems", "center"]])),
            pwField: StyleCreator$1__create_4EAC9E1E(styles, singleton(["margin", 10])),
            root: root,
        };
    });
    return (arg) => {
        const o = f1(arg);
        return Object.assign({}, o);
    };
})());

function view(model, dispatch) {
    let elems_1, elements;
    const s = useStyles();
    return createElement("div", createObj(ofArray([["className", s.root], (elems_1 = [snackError(model.ErrorMsg, () => {
        dispatch(new Message(5));
    }), snackInfo(model.InfoMsg, () => {
        dispatch(new Message(6));
    }), MuiHelpers_createElement(Paper, [["elevation", 2], ["className", s.box], (elements = toList(delay(() => ((!model.Updated) ? append(singleton_1(pageHeader("Change password")), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.pwField], ["size", "small"], ["variant", "outlined"], ["autoFocus", true], ["required", true], ["label", "Current password"], ["fullWidth", true], ["onChange", (e) => {
        dispatch(new Message(0, Browser_Types_Event__Event_get_Value(e)));
    }], ["type", "password"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.pwField], ["size", "small"], ["variant", "outlined"], ["required", true], ["label", "New password"], ["fullWidth", true], ["onChange", (e_1) => {
        dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e_1)));
    }], ["type", "password"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.pwField], ["size", "small"], ["variant", "outlined"], ["required", true], ["label", "Repeat new password"], ["fullWidth", true], ["onChange", (e_2) => {
        dispatch(new Message(2, Browser_Types_Event__Event_get_Value(e_2)));
    }], ["type", "password"]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["variant", "outlined"], ["color", "primary"], ["children", "Change"], ["onClick", (_arg_2) => {
        const old$0027 = model.OldPassword.length > 0;
        const minLength = model.NewPassword.length >= 8;
        const matches = model.NewPassword === model.RepeatPassword;
        if ((old$0027 && minLength) && matches) {
            const pr = updatePassword(model.OldPassword, model.NewPassword, dispatch);
            pr.then();
        }
        else if (!minLength) {
            dispatch(new Message(4, "New password must be at least 8 characters"));
        }
        else if (!matches) {
            dispatch(new Message(4, "Passwords don\u0027t match"));
        }
        else if (!old$0027) {
            dispatch(new Message(4, "Provide old password"));
        }
    }]])))))))))) : singleton_1(createElement("span", {
        children: "Password changed",
    }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export const passwordForm = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    return view(patternInput[0], patternInput[1]);
}, void 0, uncurry(2, void 0), void 0, "passwordForm", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/PasswordForm.fs", 162);

