import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { GenericResponse$reflection, ActiveSession$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, string_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { snackInfo, snackError, pageHeader } from "./ViewHelpers.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import TableHead from "@material-ui/core/TableHead";
import { map as map_1 } from "./fable_modules/fable-library.3.7.22/Array.js";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";

class Model extends Record {
    constructor(Active, ErrorMsg, InfoMsg) {
        super();
        this.Active = Active;
        this.ErrorMsg = ErrorMsg;
        this.InfoMsg = InfoMsg;
    }
}

function Model$reflection() {
    return record_type("Sessions.Model", [], Model, () => [["Active", option_type(array_type(ActiveSession$reflection()))], ["ErrorMsg", option_type(string_type)], ["InfoMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ActiveResponse", "DeleteAllResponse", "DismissError", "DismissInfo"];
    }
}

function Message$reflection() {
    return union_type("Sessions.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ActiveSession$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ActiveSession$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], []]);
}

function init() {
    return new Model(void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Active, "Could not delete all sessions", model.InfoMsg);
            }
            else {
                const x_1 = res_1.fields[0];
                if (x_1.Result === "success") {
                    return new Model(model.Active, model.ErrorMsg, "All sessions deleted");
                }
                else {
                    return new Model(model.Active, x_1.Message, model.InfoMsg);
                }
            }
        }
        case 2: {
            return new Model(model.Active, void 0, model.InfoMsg);
        }
        case 3: {
            return new Model(model.Active, model.ErrorMsg, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Active, "Something went wrong. Could not fetch active sessions.", model.InfoMsg);
            }
            else {
                return new Model(res.fields[0], model.ErrorMsg, model.InfoMsg);
            }
        }
    }
}

function fetchActive(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(ActiveSession$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/admin/sessions", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(ActiveSession$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(ActiveSession$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
}

function deleteAll(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(""), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/admin/sessions", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(1, _arg_2));
            return Promise.resolve();
        });
    }));
}

function view(model, dispatch) {
    let elems_6;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const toggle = patternInput[0];
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems_6 = toList(delay(() => append(singleton_1(pageHeader("Active admin logins")), delay(() => append(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(2));
    })), delay(() => append(singleton_1(snackInfo(model.InfoMsg, () => {
        dispatch(new Message(3));
    })), delay(() => {
        const matchValue = model.Active;
        if (matchValue != null) {
            const x = matchValue;
            return append(singleton_1(MuiHelpers_createElement(Button, toList(delay(() => append(singleton_1(["size", "small"]), delay(() => append((!toggle) ? singleton_1(["variant", "outlined"]) : append(singleton_1(["variant", "contained"]), delay(() => singleton_1(["color", "secondary"]))), delay(() => append(singleton_1(["children", "Delete all sessions"]), delay(() => singleton_1(["onClick", (_arg_2) => {
                if (!toggle) {
                    patternInput[1](true);
                }
                else {
                    const pr = deleteAll(dispatch);
                    pr.then();
                }
            }]))))))))))), delay(() => {
                let children_4, elements_4, children_1, children, children_3;
                return singleton_1((children_4 = singleton(MuiHelpers_createElement(Table, [["size", "small"], (elements_4 = [(children_1 = singleton((children = ofArray([MuiHelpers_createElement(TableCell, [["children", "Admin"]]), MuiHelpers_createElement(TableCell, [["children", "Agent"]]), MuiHelpers_createElement(TableCell, [["children", "IP"]]), MuiHelpers_createElement(TableCell, [["children", "Valid to"]]), MuiHelpers_createElement(TableCell, [])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_3 = singleton(map_1((y) => {
                    const children_2 = ofArray([MuiHelpers_createElement(TableCell, [["children", y.Admin]]), MuiHelpers_createElement(TableCell, [["children", y.Agent]]), MuiHelpers_createElement(TableCell, [["children", y.IP]]), MuiHelpers_createElement(TableCell, [["children", y.Until]]), MuiHelpers_createElement(TableCell, [])]);
                    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]);
                }, x)), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])), MuiHelpers_createElement(TableContainer, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])));
            }));
        }
        else {
            return singleton_1(MuiHelpers_createElement(CircularProgress, []));
        }
    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

export const sessions = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchActive(dispatch);
        pr.then();
    }, []);
    return view(patternInput[0], dispatch);
}, void 0, uncurry(2, void 0), void 0, "sessions", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/Sessions.fs", 136);

