import { Record } from "../fable_modules/fable-library.3.7.22/Types.js";
import { float64_type, anonRecord_type, array_type, bool_type, option_type, int32_type, record_type, string_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";

export class GenericResponse extends Record {
    constructor(Result, Message) {
        super();
        this.Result = Result;
        this.Message = Message;
    }
}

export function GenericResponse$reflection() {
    return record_type("ApiDataTypes.GenericResponse", [], GenericResponse, () => [["Result", string_type], ["Message", string_type]]);
}

export class LoginRequest extends Record {
    constructor(Username, Password) {
        super();
        this.Username = Username;
        this.Password = Password;
    }
}

export function LoginRequest$reflection() {
    return record_type("ApiDataTypes.LoginRequest", [], LoginRequest, () => [["Username", string_type], ["Password", string_type]]);
}

export class SessionInfo extends Record {
    constructor(Username) {
        super();
        this.Username = Username;
    }
}

export function SessionInfo$reflection() {
    return record_type("ApiDataTypes.SessionInfo", [], SessionInfo, () => [["Username", string_type]]);
}

export class UserImage extends Record {
    constructor(ImageId, UserId, Username, Updated) {
        super();
        this.ImageId = (ImageId | 0);
        this.UserId = (UserId | 0);
        this.Username = Username;
        this.Updated = Updated;
    }
}

export function UserImage$reflection() {
    return record_type("ApiDataTypes.UserImage", [], UserImage, () => [["ImageId", int32_type], ["UserId", int32_type], ["Username", string_type], ["Updated", string_type]]);
}

export class GroupImage extends Record {
    constructor(ImageId, GroupId, GroupName, UserId, Username, Updated) {
        super();
        this.ImageId = (ImageId | 0);
        this.GroupId = (GroupId | 0);
        this.GroupName = GroupName;
        this.UserId = (UserId | 0);
        this.Username = Username;
        this.Updated = Updated;
    }
}

export function GroupImage$reflection() {
    return record_type("ApiDataTypes.GroupImage", [], GroupImage, () => [["ImageId", int32_type], ["GroupId", int32_type], ["GroupName", string_type], ["UserId", int32_type], ["Username", string_type], ["Updated", string_type]]);
}

export class ActiveSession extends Record {
    constructor(Admin, Agent, IP, Until) {
        super();
        this.Admin = Admin;
        this.Agent = Agent;
        this.IP = IP;
        this.Until = Until;
    }
}

export function ActiveSession$reflection() {
    return record_type("ApiDataTypes.ActiveSession", [], ActiveSession, () => [["Admin", string_type], ["Agent", string_type], ["IP", string_type], ["Until", string_type]]);
}

export class Market extends Record {
    constructor(Id, Name, Ticker, Country, Assets) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Ticker = Ticker;
        this.Country = Country;
        this.Assets = (Assets | 0);
    }
}

export function Market$reflection() {
    return record_type("ApiDataTypes.Market", [], Market, () => [["Id", int32_type], ["Name", string_type], ["Ticker", string_type], ["Country", string_type], ["Assets", int32_type]]);
}

export class Asset extends Record {
    constructor(Id, ISIN, Name, NumShares, AssetType, Ticker, Currency, MarketId, Delisted) {
        super();
        this.Id = (Id | 0);
        this.ISIN = ISIN;
        this.Name = Name;
        this.NumShares = NumShares;
        this.AssetType = (AssetType | 0);
        this.Ticker = Ticker;
        this.Currency = Currency;
        this.MarketId = (MarketId | 0);
        this.Delisted = Delisted;
    }
}

export function Asset$reflection() {
    return record_type("ApiDataTypes.Asset", [], Asset, () => [["Id", int32_type], ["ISIN", string_type], ["Name", string_type], ["NumShares", string_type], ["AssetType", int32_type], ["Ticker", string_type], ["Currency", string_type], ["MarketId", int32_type], ["Delisted", option_type(string_type)]]);
}

export class UserSearchEntry extends Record {
    constructor(Id, Username, Email, Enabled) {
        super();
        this.Id = (Id | 0);
        this.Username = Username;
        this.Email = Email;
        this.Enabled = Enabled;
    }
}

export function UserSearchEntry$reflection() {
    return record_type("ApiDataTypes.UserSearchEntry", [], UserSearchEntry, () => [["Id", int32_type], ["Username", string_type], ["Email", string_type], ["Enabled", bool_type]]);
}

export class User extends Record {
    constructor(Id, Username, Email, EmailVerified, Enabled, Official, Joined, ImageId, Banned, RegisteredWithEmail, Google, Apple) {
        super();
        this.Id = (Id | 0);
        this.Username = Username;
        this.Email = Email;
        this.EmailVerified = EmailVerified;
        this.Enabled = Enabled;
        this.Official = Official;
        this.Joined = Joined;
        this.ImageId = ImageId;
        this.Banned = Banned;
        this.RegisteredWithEmail = RegisteredWithEmail;
        this.Google = Google;
        this.Apple = Apple;
    }
}

export function User$reflection() {
    return record_type("ApiDataTypes.User", [], User, () => [["Id", int32_type], ["Username", string_type], ["Email", string_type], ["EmailVerified", bool_type], ["Enabled", bool_type], ["Official", bool_type], ["Joined", string_type], ["ImageId", option_type(int32_type)], ["Banned", option_type(string_type)], ["RegisteredWithEmail", bool_type], ["Google", bool_type], ["Apple", bool_type]]);
}

export class Alert extends Record {
    constructor(Asset, Registered) {
        super();
        this.Asset = Asset;
        this.Registered = Registered;
    }
}

export function Alert$reflection() {
    return record_type("ApiDataTypes.Alert", [], Alert, () => [["Asset", string_type], ["Registered", string_type]]);
}

export class UserAlerts extends Record {
    constructor(InsiderTrades, Short) {
        super();
        this.InsiderTrades = InsiderTrades;
        this.Short = Short;
    }
}

export function UserAlerts$reflection() {
    return record_type("ApiDataTypes.UserAlerts", [], UserAlerts, () => [["InsiderTrades", array_type(Alert$reflection())], ["Short", array_type(Alert$reflection())]]);
}

export class Transaction extends Record {
    constructor(Date$, Amount, Currency, Store, Refunded) {
        super();
        this.Date = Date$;
        this.Amount = (Amount | 0);
        this.Currency = Currency;
        this.Store = (Store | 0);
        this.Refunded = Refunded;
    }
}

export function Transaction$reflection() {
    return record_type("ApiDataTypes.Transaction", [], Transaction, () => [["Date", string_type], ["Amount", int32_type], ["Currency", string_type], ["Store", int32_type], ["Refunded", bool_type]]);
}

export class Subscription extends Record {
    constructor(UserId, ProductId, Start, End, Status, Transactions) {
        super();
        this.UserId = (UserId | 0);
        this.ProductId = ProductId;
        this.Start = Start;
        this.End = End;
        this.Status = (Status | 0);
        this.Transactions = Transactions;
    }
}

export function Subscription$reflection() {
    return record_type("ApiDataTypes.Subscription", [], Subscription, () => [["UserId", int32_type], ["ProductId", string_type], ["Start", string_type], ["End", string_type], ["Status", int32_type], ["Transactions", array_type(Transaction$reflection())]]);
}

export class Favorite extends Record {
    constructor(Asset, Ticker, Market) {
        super();
        this.Asset = Asset;
        this.Ticker = Ticker;
        this.Market = Market;
    }
}

export function Favorite$reflection() {
    return record_type("ApiDataTypes.Favorite", [], Favorite, () => [["Asset", string_type], ["Ticker", string_type], ["Market", string_type]]);
}

export class UserMeta extends Record {
    constructor(Id, Username) {
        super();
        this.Id = (Id | 0);
        this.Username = Username;
    }
}

export function UserMeta$reflection() {
    return record_type("ApiDataTypes.UserMeta", [], UserMeta, () => [["Id", int32_type], ["Username", string_type]]);
}

export class UserDevice extends Record {
    constructor(OS, Model, Registered, Push, Version, Build) {
        super();
        this.OS = (OS | 0);
        this.Model = Model;
        this.Registered = Registered;
        this.Push = Push;
        this.Version = Version;
        this.Build = (Build | 0);
    }
}

export function UserDevice$reflection() {
    return record_type("ApiDataTypes.UserDevice", [], UserDevice, () => [["OS", int32_type], ["Model", string_type], ["Registered", string_type], ["Push", bool_type], ["Version", string_type], ["Build", int32_type]]);
}

export class NotificationLog extends Record {
    constructor(OS, ReasonId, Sent, Content) {
        super();
        this.OS = (OS | 0);
        this.ReasonId = (ReasonId | 0);
        this.Sent = Sent;
        this.Content = Content;
    }
}

export function NotificationLog$reflection() {
    return record_type("ApiDataTypes.NotificationLog", [], NotificationLog, () => [["OS", int32_type], ["ReasonId", int32_type], ["Sent", string_type], ["Content", string_type]]);
}

export class BanForm extends Record {
    constructor(UserId, Reason, Until) {
        super();
        this.UserId = (UserId | 0);
        this.Reason = Reason;
        this.Until = Until;
    }
}

export function BanForm$reflection() {
    return record_type("ApiDataTypes.BanForm", [], BanForm, () => [["UserId", int32_type], ["Reason", string_type], ["Until", string_type]]);
}

export class Ban extends Record {
    constructor(Id, Reason, Until, Since) {
        super();
        this.Id = (Id | 0);
        this.Reason = Reason;
        this.Until = Until;
        this.Since = Since;
    }
}

export function Ban$reflection() {
    return record_type("ApiDataTypes.Ban", [], Ban, () => [["Id", int32_type], ["Reason", string_type], ["Until", string_type], ["Since", string_type]]);
}

export class ContentReport extends Record {
    constructor(ReportId, ContentId, ContentType, PostInfo, ReportedId, Reported, ReporterId, Reporter, ReasonId, ReportTime, Comment$) {
        super();
        this.ReportId = (ReportId | 0);
        this.ContentId = (ContentId | 0);
        this.ContentType = (ContentType | 0);
        this.PostInfo = PostInfo;
        this.ReportedId = (ReportedId | 0);
        this.Reported = Reported;
        this.ReporterId = (ReporterId | 0);
        this.Reporter = Reporter;
        this.ReasonId = (ReasonId | 0);
        this.ReportTime = ReportTime;
        this.Comment = Comment$;
    }
}

export function ContentReport$reflection() {
    return record_type("ApiDataTypes.ContentReport", [], ContentReport, () => [["ReportId", int32_type], ["ContentId", int32_type], ["ContentType", int32_type], ["PostInfo", option_type(anonRecord_type(["Content", string_type], ["Id", int32_type], ["Parent", string_type], ["Published", string_type], ["Updated", option_type(string_type)]))], ["ReportedId", int32_type], ["Reported", string_type], ["ReporterId", int32_type], ["Reporter", string_type], ["ReasonId", int32_type], ["ReportTime", string_type], ["Comment", string_type]]);
}

export class UserReport extends Record {
    constructor(Id, ReportedUserId, ReportedUsername, ReportedImageId, ReportingUserId, ReportingUsername, ReportingImageId, ReasonId, Reason) {
        super();
        this.Id = (Id | 0);
        this.ReportedUserId = (ReportedUserId | 0);
        this.ReportedUsername = ReportedUsername;
        this.ReportedImageId = ReportedImageId;
        this.ReportingUserId = (ReportingUserId | 0);
        this.ReportingUsername = ReportingUsername;
        this.ReportingImageId = ReportingImageId;
        this.ReasonId = (ReasonId | 0);
        this.Reason = Reason;
    }
}

export function UserReport$reflection() {
    return record_type("ApiDataTypes.UserReport", [], UserReport, () => [["Id", int32_type], ["ReportedUserId", int32_type], ["ReportedUsername", string_type], ["ReportedImageId", option_type(int32_type)], ["ReportingUserId", int32_type], ["ReportingUsername", string_type], ["ReportingImageId", option_type(int32_type)], ["ReasonId", int32_type], ["Reason", string_type]]);
}

export class GroupReport extends Record {
    constructor(Id, GroupId, GroupName, GroupImageId, Public, UserId, Username, UserImageId, ReasonId, Reason) {
        super();
        this.Id = (Id | 0);
        this.GroupId = (GroupId | 0);
        this.GroupName = GroupName;
        this.GroupImageId = GroupImageId;
        this.Public = Public;
        this.UserId = (UserId | 0);
        this.Username = Username;
        this.UserImageId = UserImageId;
        this.ReasonId = (ReasonId | 0);
        this.Reason = Reason;
    }
}

export function GroupReport$reflection() {
    return record_type("ApiDataTypes.GroupReport", [], GroupReport, () => [["Id", int32_type], ["GroupId", int32_type], ["GroupName", string_type], ["GroupImageId", option_type(int32_type)], ["Public", bool_type], ["UserId", int32_type], ["Username", string_type], ["UserImageId", option_type(int32_type)], ["ReasonId", int32_type], ["Reason", string_type]]);
}

export class GroupSearchEntry extends Record {
    constructor(Id, Name, Public, Enabled) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Public = Public;
        this.Enabled = Enabled;
    }
}

export function GroupSearchEntry$reflection() {
    return record_type("ApiDataTypes.GroupSearchEntry", [], GroupSearchEntry, () => [["Id", int32_type], ["Name", string_type], ["Public", bool_type], ["Enabled", bool_type]]);
}

export class Group extends Record {
    constructor(Id, Name, Desc, Public, Official, Created, ImageId, NumMembers) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.Public = Public;
        this.Official = Official;
        this.Created = Created;
        this.ImageId = ImageId;
        this.NumMembers = (NumMembers | 0);
    }
}

export function Group$reflection() {
    return record_type("ApiDataTypes.Group", [], Group, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["Public", bool_type], ["Official", bool_type], ["Created", string_type], ["ImageId", option_type(int32_type)], ["NumMembers", int32_type]]);
}

export class GroupMember extends Record {
    constructor(Id, UserId, Username, UserImgId, GroupId, Group, GroupImgId, IsAdmin, IsOwner) {
        super();
        this.Id = (Id | 0);
        this.UserId = (UserId | 0);
        this.Username = Username;
        this.UserImgId = UserImgId;
        this.GroupId = (GroupId | 0);
        this.Group = Group;
        this.GroupImgId = GroupImgId;
        this.IsAdmin = IsAdmin;
        this.IsOwner = IsOwner;
    }
}

export function GroupMember$reflection() {
    return record_type("ApiDataTypes.GroupMember", [], GroupMember, () => [["Id", int32_type], ["UserId", int32_type], ["Username", string_type], ["UserImgId", option_type(int32_type)], ["GroupId", int32_type], ["Group", string_type], ["GroupImgId", option_type(int32_type)], ["IsAdmin", bool_type], ["IsOwner", bool_type]]);
}

export class CsvForm extends Record {
    constructor(Content) {
        super();
        this.Content = Content;
    }
}

export function CsvForm$reflection() {
    return record_type("ApiDataTypes.CsvForm", [], CsvForm, () => [["Content", string_type]]);
}

export class QuoteFormEntry extends Record {
    constructor(Date$, Close, Open, Low, High, Volume) {
        super();
        this.Date = Date$;
        this.Close = Close;
        this.Open = Open;
        this.Low = Low;
        this.High = High;
        this.Volume = (Volume | 0);
    }
}

export function QuoteFormEntry$reflection() {
    return record_type("ApiDataTypes.QuoteFormEntry", [], QuoteFormEntry, () => [["Date", string_type], ["Close", float64_type], ["Open", float64_type], ["Low", float64_type], ["High", float64_type], ["Volume", int32_type]]);
}

export class IntraQuoteFormEntry extends Record {
    constructor(Asset, ISIN, Date$, Close, Open, Low, High, Volume) {
        super();
        this.Asset = Asset;
        this.ISIN = ISIN;
        this.Date = Date$;
        this.Close = Close;
        this.Open = Open;
        this.Low = Low;
        this.High = High;
        this.Volume = (Volume | 0);
    }
}

export function IntraQuoteFormEntry$reflection() {
    return record_type("ApiDataTypes.IntraQuoteFormEntry", [], IntraQuoteFormEntry, () => [["Asset", string_type], ["ISIN", string_type], ["Date", string_type], ["Close", float64_type], ["Open", float64_type], ["Low", float64_type], ["High", float64_type], ["Volume", int32_type]]);
}

export class QuotesForm extends Record {
    constructor(AssetId, Entries) {
        super();
        this.AssetId = (AssetId | 0);
        this.Entries = Entries;
    }
}

export function QuotesForm$reflection() {
    return record_type("ApiDataTypes.QuotesForm", [], QuotesForm, () => [["AssetId", int32_type], ["Entries", array_type(QuoteFormEntry$reflection())]]);
}

export class NumSharesForm extends Record {
    constructor(AssetId, NumShares, Log) {
        super();
        this.AssetId = (AssetId | 0);
        this.NumShares = NumShares;
        this.Log = Log;
    }
}

export function NumSharesForm$reflection() {
    return record_type("ApiDataTypes.NumSharesForm", [], NumSharesForm, () => [["AssetId", int32_type], ["NumShares", string_type], ["Log", option_type(anonRecord_type(["Date", string_type], ["Diff", string_type]))]]);
}

export class PotentialNumSharesChange extends Record {
    constructor(Id, Ticker, Market, Title, Body, Scraped, Handled) {
        super();
        this.Id = (Id | 0);
        this.Ticker = Ticker;
        this.Market = Market;
        this.Title = Title;
        this.Body = Body;
        this.Scraped = Scraped;
        this.Handled = Handled;
    }
}

export function PotentialNumSharesChange$reflection() {
    return record_type("ApiDataTypes.PotentialNumSharesChange", [], PotentialNumSharesChange, () => [["Id", int32_type], ["Ticker", string_type], ["Market", string_type], ["Title", string_type], ["Body", string_type], ["Scraped", string_type], ["Handled", bool_type]]);
}

export class ParsedAsset extends Record {
    constructor(Name, ISIN, Ticker, Currency) {
        super();
        this.Name = Name;
        this.ISIN = ISIN;
        this.Ticker = Ticker;
        this.Currency = Currency;
    }
}

export function ParsedAsset$reflection() {
    return record_type("ApiDataTypes.ParsedAsset", [], ParsedAsset, () => [["Name", string_type], ["ISIN", string_type], ["Ticker", string_type], ["Currency", string_type]]);
}

export class TradeExchangeInfo extends Record {
    constructor(AvgPrice, Currency) {
        super();
        this.AvgPrice = AvgPrice;
        this.Currency = Currency;
    }
}

export function TradeExchangeInfo$reflection() {
    return record_type("ApiDataTypes.TradeExchangeInfo", [], TradeExchangeInfo, () => [["AvgPrice", float64_type], ["Currency", string_type]]);
}

export class InsiderTrade extends Record {
    constructor(Id, Date$, AssetId, Asset, Market, Insider, NumShares, AvgPrice, NewTotal, Verified, Exchanged, Removed, SourceUrl) {
        super();
        this.Id = (Id | 0);
        this.Date = Date$;
        this.AssetId = (AssetId | 0);
        this.Asset = Asset;
        this.Market = Market;
        this.Insider = Insider;
        this.NumShares = (NumShares | 0);
        this.AvgPrice = AvgPrice;
        this.NewTotal = NewTotal;
        this.Verified = Verified;
        this.Exchanged = Exchanged;
        this.Removed = Removed;
        this.SourceUrl = SourceUrl;
    }
}

export function InsiderTrade$reflection() {
    return record_type("ApiDataTypes.InsiderTrade", [], InsiderTrade, () => [["Id", int32_type], ["Date", string_type], ["AssetId", int32_type], ["Asset", string_type], ["Market", string_type], ["Insider", string_type], ["NumShares", int32_type], ["AvgPrice", option_type(float64_type)], ["NewTotal", option_type(int32_type)], ["Verified", bool_type], ["Exchanged", option_type(TradeExchangeInfo$reflection())], ["Removed", bool_type], ["SourceUrl", string_type]]);
}

export class LastAssetQuoteDate extends Record {
    constructor(AssetId, Name, Ticker, MarketTicker, LastQuoteDate) {
        super();
        this.AssetId = (AssetId | 0);
        this.Name = Name;
        this.Ticker = Ticker;
        this.MarketTicker = MarketTicker;
        this.LastQuoteDate = LastQuoteDate;
    }
}

export function LastAssetQuoteDate$reflection() {
    return record_type("ApiDataTypes.LastAssetQuoteDate", [], LastAssetQuoteDate, () => [["AssetId", int32_type], ["Name", string_type], ["Ticker", string_type], ["MarketTicker", string_type], ["LastQuoteDate", option_type(string_type)]]);
}

export class Statistics_FavoriteCount extends Record {
    constructor(Ticker, Market, Num) {
        super();
        this.Ticker = Ticker;
        this.Market = Market;
        this.Num = (Num | 0);
    }
}

export function Statistics_FavoriteCount$reflection() {
    return record_type("ApiDataTypes.Statistics.FavoriteCount", [], Statistics_FavoriteCount, () => [["Ticker", string_type], ["Market", string_type], ["Num", int32_type]]);
}

export class Statistics_UserSummary extends Record {
    constructor(Total, Verified, Enabled, EnabledVerified, Google, Apple) {
        super();
        this.Total = (Total | 0);
        this.Verified = (Verified | 0);
        this.Enabled = (Enabled | 0);
        this.EnabledVerified = (EnabledVerified | 0);
        this.Google = (Google | 0);
        this.Apple = (Apple | 0);
    }
}

export function Statistics_UserSummary$reflection() {
    return record_type("ApiDataTypes.Statistics.UserSummary", [], Statistics_UserSummary, () => [["Total", int32_type], ["Verified", int32_type], ["Enabled", int32_type], ["EnabledVerified", int32_type], ["Google", int32_type], ["Apple", int32_type]]);
}

export class Statistics_DateCountEntry extends Record {
    constructor(Day, Month, Year, Num) {
        super();
        this.Day = (Day | 0);
        this.Month = (Month | 0);
        this.Year = (Year | 0);
        this.Num = (Num | 0);
    }
}

export function Statistics_DateCountEntry$reflection() {
    return record_type("ApiDataTypes.Statistics.DateCountEntry", [], Statistics_DateCountEntry, () => [["Day", int32_type], ["Month", int32_type], ["Year", int32_type], ["Num", int32_type]]);
}

export class Statistics_ViewSummary extends Record {
    constructor(Total, Assets, Pages, TotalAssets, TotalPages, TotalTotal) {
        super();
        this.Total = (Total | 0);
        this.Assets = (Assets | 0);
        this.Pages = (Pages | 0);
        this.TotalAssets = (TotalAssets | 0);
        this.TotalPages = (TotalPages | 0);
        this.TotalTotal = (TotalTotal | 0);
    }
}

export function Statistics_ViewSummary$reflection() {
    return record_type("ApiDataTypes.Statistics.ViewSummary", [], Statistics_ViewSummary, () => [["Total", int32_type], ["Assets", int32_type], ["Pages", int32_type], ["TotalAssets", int32_type], ["TotalPages", int32_type], ["TotalTotal", int32_type]]);
}

export class Statistics_PageViewEntry extends Record {
    constructor(Path, Views) {
        super();
        this.Path = Path;
        this.Views = (Views | 0);
    }
}

export function Statistics_PageViewEntry$reflection() {
    return record_type("ApiDataTypes.Statistics.PageViewEntry", [], Statistics_PageViewEntry, () => [["Path", string_type], ["Views", int32_type]]);
}

export class Statistics_AssetViewEntry extends Record {
    constructor(Asset, Views) {
        super();
        this.Asset = Asset;
        this.Views = (Views | 0);
    }
}

export function Statistics_AssetViewEntry$reflection() {
    return record_type("ApiDataTypes.Statistics.AssetViewEntry", [], Statistics_AssetViewEntry, () => [["Asset", string_type], ["Views", int32_type]]);
}

export class Statistics_Latest extends Record {
    constructor(Users, Groups, Reviews, AssetPosts, GroupPosts) {
        super();
        this.Users = Users;
        this.Groups = Groups;
        this.Reviews = Reviews;
        this.AssetPosts = AssetPosts;
        this.GroupPosts = GroupPosts;
    }
}

export function Statistics_Latest$reflection() {
    return record_type("ApiDataTypes.Statistics.Latest", [], Statistics_Latest, () => [["Users", array_type(string_type)], ["Groups", array_type(string_type)], ["Reviews", array_type(string_type)], ["AssetPosts", array_type(string_type)], ["GroupPosts", array_type(string_type)]]);
}

export class Statistics_PostSummary extends Record {
    constructor(TotalReviews, Reviews, TotalAssetPosts, AssetPosts, TotalGroupPosts, GroupPosts) {
        super();
        this.TotalReviews = (TotalReviews | 0);
        this.Reviews = (Reviews | 0);
        this.TotalAssetPosts = (TotalAssetPosts | 0);
        this.AssetPosts = (AssetPosts | 0);
        this.TotalGroupPosts = (TotalGroupPosts | 0);
        this.GroupPosts = (GroupPosts | 0);
    }
}

export function Statistics_PostSummary$reflection() {
    return record_type("ApiDataTypes.Statistics.PostSummary", [], Statistics_PostSummary, () => [["TotalReviews", int32_type], ["Reviews", int32_type], ["TotalAssetPosts", int32_type], ["AssetPosts", int32_type], ["TotalGroupPosts", int32_type], ["GroupPosts", int32_type]]);
}

export class Statistics_ViewPeriodEntry extends Record {
    constructor(Day, Total, Assets, Pages) {
        super();
        this.Day = Day;
        this.Total = (Total | 0);
        this.Assets = (Assets | 0);
        this.Pages = (Pages | 0);
    }
}

export function Statistics_ViewPeriodEntry$reflection() {
    return record_type("ApiDataTypes.Statistics.ViewPeriodEntry", [], Statistics_ViewPeriodEntry, () => [["Day", string_type], ["Total", int32_type], ["Assets", int32_type], ["Pages", int32_type]]);
}

export class Statistics_ReferrerEntry extends Record {
    constructor(Referrer, Num) {
        super();
        this.Referrer = Referrer;
        this.Num = (Num | 0);
    }
}

export function Statistics_ReferrerEntry$reflection() {
    return record_type("ApiDataTypes.Statistics.ReferrerEntry", [], Statistics_ReferrerEntry, () => [["Referrer", string_type], ["Num", int32_type]]);
}

export class Statistics_TrafficStats extends Record {
    constructor(TopAssetsToday, TopReferrersToday, TopAssetsLastSeven, TopReferrersLastSeven, NumReferredToday, NumReferredLastSeven, NumAssetViewsToday, NumAssetViewsLastSeven) {
        super();
        this.TopAssetsToday = TopAssetsToday;
        this.TopReferrersToday = TopReferrersToday;
        this.TopAssetsLastSeven = TopAssetsLastSeven;
        this.TopReferrersLastSeven = TopReferrersLastSeven;
        this.NumReferredToday = (NumReferredToday | 0);
        this.NumReferredLastSeven = (NumReferredLastSeven | 0);
        this.NumAssetViewsToday = (NumAssetViewsToday | 0);
        this.NumAssetViewsLastSeven = (NumAssetViewsLastSeven | 0);
    }
}

export function Statistics_TrafficStats$reflection() {
    return record_type("ApiDataTypes.Statistics.TrafficStats", [], Statistics_TrafficStats, () => [["TopAssetsToday", array_type(Statistics_AssetViewEntry$reflection())], ["TopReferrersToday", array_type(Statistics_ReferrerEntry$reflection())], ["TopAssetsLastSeven", array_type(Statistics_AssetViewEntry$reflection())], ["TopReferrersLastSeven", array_type(Statistics_ReferrerEntry$reflection())], ["NumReferredToday", int32_type], ["NumReferredLastSeven", int32_type], ["NumAssetViewsToday", int32_type], ["NumAssetViewsLastSeven", int32_type]]);
}

export class Statistics_UsageSummary extends Record {
    constructor(Today, LastSeven, ThisWeek, PreviousWeek) {
        super();
        this.Today = (Today | 0);
        this.LastSeven = (LastSeven | 0);
        this.ThisWeek = (ThisWeek | 0);
        this.PreviousWeek = (PreviousWeek | 0);
    }
}

export function Statistics_UsageSummary$reflection() {
    return record_type("ApiDataTypes.Statistics.UsageSummary", [], Statistics_UsageSummary, () => [["Today", int32_type], ["LastSeven", int32_type], ["ThisWeek", int32_type], ["PreviousWeek", int32_type]]);
}

export class Statistics_AppStats extends Record {
    constructor(AppleDeviceIds, GoogleDeviceIds, InsiderTradeAlerts, ShortAlerts, InsiderAlertsSent, ShortAlertsSent, SignedInUsers) {
        super();
        this.AppleDeviceIds = (AppleDeviceIds | 0);
        this.GoogleDeviceIds = (GoogleDeviceIds | 0);
        this.InsiderTradeAlerts = (InsiderTradeAlerts | 0);
        this.ShortAlerts = (ShortAlerts | 0);
        this.InsiderAlertsSent = (InsiderAlertsSent | 0);
        this.ShortAlertsSent = (ShortAlertsSent | 0);
        this.SignedInUsers = (SignedInUsers | 0);
    }
}

export function Statistics_AppStats$reflection() {
    return record_type("ApiDataTypes.Statistics.AppStats", [], Statistics_AppStats, () => [["AppleDeviceIds", int32_type], ["GoogleDeviceIds", int32_type], ["InsiderTradeAlerts", int32_type], ["ShortAlerts", int32_type], ["InsiderAlertsSent", int32_type], ["ShortAlertsSent", int32_type], ["SignedInUsers", int32_type]]);
}

export class Statistics_SubscriptionStats extends Record {
    constructor(AllTimeRev, ActiveSubs, TotalSubs, CancelledSubs, MonthlyRecurringRev) {
        super();
        this.AllTimeRev = (AllTimeRev | 0);
        this.ActiveSubs = (ActiveSubs | 0);
        this.TotalSubs = (TotalSubs | 0);
        this.CancelledSubs = (CancelledSubs | 0);
        this.MonthlyRecurringRev = (MonthlyRecurringRev | 0);
    }
}

export function Statistics_SubscriptionStats$reflection() {
    return record_type("ApiDataTypes.Statistics.SubscriptionStats", [], Statistics_SubscriptionStats, () => [["AllTimeRev", int32_type], ["ActiveSubs", int32_type], ["TotalSubs", int32_type], ["CancelledSubs", int32_type], ["MonthlyRecurringRev", int32_type]]);
}

export class Feedback extends Record {
    constructor(Id, Topic, Content, Submitted, User) {
        super();
        this.Id = (Id | 0);
        this.Topic = (Topic | 0);
        this.Content = Content;
        this.Submitted = Submitted;
        this.User = User;
    }
}

export function Feedback$reflection() {
    return record_type("ApiDataTypes.Feedback", [], Feedback, () => [["Id", int32_type], ["Topic", int32_type], ["Content", string_type], ["Submitted", string_type], ["User", UserMeta$reflection()]]);
}

export class AutoModPost extends Record {
    constructor(Id, Ticker, Market, Published, Content) {
        super();
        this.Id = Id;
        this.Ticker = Ticker;
        this.Market = Market;
        this.Published = Published;
        this.Content = Content;
    }
}

export function AutoModPost$reflection() {
    return record_type("ApiDataTypes.AutoModPost", [], AutoModPost, () => [["Id", string_type], ["Ticker", string_type], ["Market", string_type], ["Published", string_type], ["Content", string_type]]);
}

