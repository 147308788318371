import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { Market, Market$reflection } from "./Shared/ApiDataTypes.js";
import { tuple_type, unit_type, equals, obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { sortBy, map, append } from "./fable_modules/fable-library.3.7.22/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map as map_1, defaultArg } from "./fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { comparePrimitives, int32ToString, createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { createElement } from "react";
import { pageHeader, snackError } from "./ViewHelpers.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";

class Model extends Record {
    constructor(Markets, ShowAddForm, Edit, ErrorMsg) {
        super();
        this.Markets = Markets;
        this.ShowAddForm = ShowAddForm;
        this.Edit = Edit;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Markets.Model", [], Model, () => [["Markets", option_type(array_type(Market$reflection()))], ["ShowAddForm", bool_type], ["Edit", option_type(Market$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MarketsResponse", "ToggleAddForm", "AddResponse", "Edit", "EditResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Markets.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Market$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Market$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Market$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Market$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Market$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Market$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Market$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init() {
    return new Model(void 0, false, void 0, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Markets, !model.ShowAddForm, model.Edit, model.ErrorMsg);
        }
        case 2: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                const err_1 = res_1.fields[0];
                return new Model(model.Markets, model.ShowAddForm, model.Edit, (err_1.tag === 2) ? (((err_1.fields[0].status) === 401) ? "Authentication failed" : "Could not add market.") : "Could not add market.");
            }
            else {
                const x_1 = res_1.fields[0];
                return new Model((matchValue = model.Markets, (matchValue != null) ? append([x_1], matchValue) : [x_1]), false, model.Edit, model.ErrorMsg);
            }
        }
        case 3: {
            return new Model(model.Markets, false, msg.fields[0], model.ErrorMsg);
        }
        case 4: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                const err_2 = res_2.fields[0];
                return new Model(model.Markets, model.ShowAddForm, model.Edit, (err_2.tag === 2) ? (((err_2.fields[0].status) === 401) ? "Authentication failed" : "Could not update market.") : "Could not update market.");
            }
            else {
                const x_5 = res_2.fields[0];
                return new Model((matchValue_1 = model.Markets, (matchValue_1 != null) ? map((z_1) => {
                    if (z_1.Id === x_5.Id) {
                        return x_5;
                    }
                    else {
                        return z_1;
                    }
                }, matchValue_1) : (void 0)), model.ShowAddForm, void 0, model.ErrorMsg);
            }
        }
        case 5: {
            return new Model(model.Markets, model.ShowAddForm, model.Edit, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Markets, model.ShowAddForm, model.Edit, "Something went wrong. Could not load markets.");
            }
            else {
                return new Model(res.fields[0], model.ShowAddForm, model.Edit, model.ErrorMsg);
            }
        }
    }
}

function fetchMarkets(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(Market$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/market", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(Market$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(Market$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function addMarket(x, pw, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(tuple_type(string_type, Market$reflection()), void 0, void 0, void 0)([pw, x]), (decoder = Auto_generateBoxedDecoder_79988AEF(Market$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/market", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Market$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Market$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(2, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function editMarket(x, pw, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(tuple_type(string_type, Market$reflection()), void 0, void 0, void 0)([pw, x]), (decoder = Auto_generateBoxedDecoder_79988AEF(Market$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "PUT"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/market", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(Market$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(Market$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(4, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function emptryMarket() {
    return new Market(-1, "", "", "", 0);
}

function AddForm(addFormInputProps) {
    let elements_2, children, children_1;
    const dispatch = addFormInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(emptryMarket());
    const updateMarket = patternInput[1];
    const market = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const pw = patternInput_1[0];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        dispatch(new Message(1));
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Add market"]]), (children = ofArray([MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", market.Name]), delay(() => append_1(singleton_1(["autoFocus", true]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Name"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e) => {
        updateMarket(new Market(market.Id, Browser_Types_Event__Event_get_Value(e), market.Ticker, market.Country, market.Assets));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => ((market.Name.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", market.Ticker]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Ticker"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_1) => {
        updateMarket(new Market(market.Id, market.Name, Browser_Types_Event__Event_get_Value(e_1), market.Country, market.Assets));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1((market.Ticker.length === 0) ? singleton_1(["error", true]) : empty_1(), delay(() => singleton_1(["style", {
        marginTop: 20,
        marginBottom: 20,
    }])))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", market.Country]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Country"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_2) => {
        updateMarket(new Market(market.Id, market.Name, market.Ticker, Browser_Types_Event__Event_get_Value(e_2), market.Assets));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["style", {
        marginBottom: 20,
    }]), delay(() => ((market.Country.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", pw]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Your password"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_3) => {
        patternInput_1[1](Browser_Types_Event__Event_get_Value(e_3));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["type", "password"]), delay(() => ((pw.length === 0) ? singleton_1(["error", true]) : empty_1()))))))))))))))))))))]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["onClick", (_arg_2) => {
        dispatch(new Message(3, void 0));
    }], ["children", "Cancel"]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", ((market.Name.length === 0) ? true : (market.Ticker.length === 0)) ? true : (market.Country.length === 0)], ["onClick", (_arg_3) => {
        if ((((market.Name.length > 0) && (market.Ticker.length > 0)) && (market.Country.length > 0)) && (pw.length > 0)) {
            addMarket(market, pw, dispatch);
        }
    }], ["children", "Add"]])]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function EditForm(editFormInputProps) {
    let elements_2, children, children_1;
    const dispatch = editFormInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(editFormInputProps.m);
    const updateMarket = patternInput[1];
    const market = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const pw = patternInput_1[0];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        dispatch(new Message(3, void 0));
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Update market"]]), (children = ofArray([MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", market.Name]), delay(() => append_1(singleton_1(["autoFocus", true]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Name"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e) => {
        updateMarket(new Market(market.Id, Browser_Types_Event__Event_get_Value(e), market.Ticker, market.Country, market.Assets));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => ((market.Name.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", market.Ticker]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Ticker"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_1) => {
        updateMarket(new Market(market.Id, market.Name, Browser_Types_Event__Event_get_Value(e_1), market.Country, market.Assets));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1((market.Ticker.length === 0) ? singleton_1(["error", true]) : empty_1(), delay(() => singleton_1(["style", {
        marginTop: 20,
        marginBottom: 20,
    }])))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", market.Country]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Country"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_2) => {
        updateMarket(new Market(market.Id, market.Name, market.Ticker, Browser_Types_Event__Event_get_Value(e_2), market.Assets));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["style", {
        marginBottom: 20,
    }]), delay(() => ((market.Country.length === 0) ? singleton_1(["error", true]) : empty_1())))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["defaultValue", pw]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["label", "Your password"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_3) => {
        patternInput_1[1](Browser_Types_Event__Event_get_Value(e_3));
    }]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["type", "password"]), delay(() => ((pw.length === 0) ? singleton_1(["error", true]) : empty_1()))))))))))))))))))))]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = ofArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["onClick", (_arg_2) => {
        dispatch(new Message(3, void 0));
    }], ["children", "Cancel"]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", ((market.Name.length === 0) ? true : (market.Ticker.length === 0)) ? true : (market.Country.length === 0)], ["onClick", (_arg_3) => {
        if ((((market.Name.length > 0) && (market.Ticker.length > 0)) && (market.Country.length > 0)) && (pw.length > 0)) {
            editMarket(market, pw, dispatch);
        }
    }], ["children", "Update"]])]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

function view(model, dispatch) {
    let elems_7;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems_7 = toList(delay(() => append_1(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(5));
    })), delay(() => append_1(singleton_1(pageHeader("Markets")), delay(() => {
        const matchValue = model.Markets;
        if (matchValue != null) {
            const x = matchValue;
            return append_1(model.ShowAddForm ? singleton_1(createElement(AddForm, {
                dispatch: dispatch,
            })) : empty_1(), delay(() => {
                let matchValue_1, m;
                return append_1((matchValue_1 = model.Edit, (matchValue_1 != null) ? ((m = matchValue_1, singleton_1(createElement(EditForm, {
                    m: m,
                    dispatch: dispatch,
                })))) : ((empty_1()))), delay(() => append_1(singleton_1(MuiHelpers_createElement(Button, [["variant", "outlined"], ["color", "primary"], ["children", "Add market"], ["onClick", (_arg_1) => {
                    dispatch(new Message(1));
                }], ["style", {
                    margin: 20,
                }]])), delay(() => {
                    let elements_6, elements_5, children_1, children, children_4;
                    return singleton_1(MuiHelpers_createElement(TableContainer, [(elements_6 = [MuiHelpers_createElement(Table, [["size", "small"], (elements_5 = [(children_1 = singleton((children = ofArray([MuiHelpers_createElement(TableCell, [["children", "Name"]]), MuiHelpers_createElement(TableCell, [["children", "Ticker"]]), MuiHelpers_createElement(TableCell, [["children", "Country"]]), MuiHelpers_createElement(TableCell, [["children", "# Stocks"]]), MuiHelpers_createElement(TableCell, [])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_4 = singleton(map((y_2) => {
                        let children_2;
                        const children_3 = ofArray([MuiHelpers_createElement(TableCell, [["children", y_2.Name]]), MuiHelpers_createElement(TableCell, [["children", y_2.Ticker]]), MuiHelpers_createElement(TableCell, [["children", y_2.Country]]), MuiHelpers_createElement(TableCell, [["children", int32ToString(y_2.Assets)]]), (children_2 = singleton(MuiHelpers_createElement(Button, [["size", "small"], ["onClick", (_arg_2) => {
                            dispatch(new Message(3, y_2));
                        }], ["children", "Edit"]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))]);
                        return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]);
                    }, sortBy((y) => y.Name, x, {
                        Compare: comparePrimitives,
                    }))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_6))])]));
                }))));
            }));
        }
        else {
            return singleton_1(MuiHelpers_createElement(CircularProgress, []));
        }
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

export function Markets() {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchMarkets(dispatch);
    });
    return view(patternInput[0], dispatch);
}

