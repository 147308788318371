import { toString as toString_1, Record, Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, record_type, option_type, string_type, array_type, union_type, int32_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { GenericResponse$reflection, ContentReport$reflection } from "../Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { map as map_1, maxBy, append } from "../fable_modules/fable-library.3.7.22/Array.js";
import { createObj, uncurry, comparePrimitives } from "../fable_modules/fable-library.3.7.22/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.22/String.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { BanForm } from "../BanForm.js";
import { ReportReason_FromInt_Z524259A4 } from "../Shared/Shared.js";
import Button from "@material-ui/core/Button";
import { snackInfo, snackError } from "../ViewHelpers.js";

class PostType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Review", "ReviewReply", "GroupPost", "GroupPostReply", "AssetPost", "AssetPostReply", "Invalid"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Review reply";
            }
            case 2: {
                return "Group post";
            }
            case 3: {
                return "Group post reply";
            }
            case 4: {
                return "Asset post";
            }
            case 5: {
                return "Asset post reply";
            }
            case 6: {
                return "Unknown content type";
            }
            default: {
                return "Review";
            }
        }
    }
}

function PostType$reflection() {
    return union_type("ContentReports.PostType", [], PostType, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], []]);
}

function PostType_FromInt(typeId, contentId) {
    switch (typeId) {
        case 1: {
            return new PostType(0, contentId);
        }
        case 2: {
            return new PostType(1, contentId);
        }
        case 3: {
            return new PostType(2, contentId);
        }
        case 4: {
            return new PostType(3, contentId);
        }
        case 5: {
            return new PostType(4, contentId);
        }
        case 6: {
            return new PostType(5, contentId);
        }
        default: {
            return new PostType(6);
        }
    }
}

class Model extends Record {
    constructor(Reports, LastId, ErrorMsg, InfoMsg) {
        super();
        this.Reports = Reports;
        this.LastId = (LastId | 0);
        this.ErrorMsg = ErrorMsg;
        this.InfoMsg = InfoMsg;
    }
}

function Model$reflection() {
    return record_type("ContentReports.Model", [], Model, () => [["Reports", array_type(ContentReport$reflection())], ["LastId", int32_type], ["ErrorMsg", option_type(string_type)], ["InfoMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ReportsResponse", "DismissError", "DismissInfo", "RemoveReport", "SetErrorMsg", "SetInfo"];
    }
}

function Message$reflection() {
    return union_type("ContentReports.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ContentReport$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ContentReport$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]]]);
}

function init() {
    return new Model([], 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.Reports, model.LastId, void 0, model.InfoMsg);
        }
        case 2: {
            return new Model(model.Reports, model.LastId, model.ErrorMsg, void 0);
        }
        case 3: {
            return new Model(model.Reports.filter((x_2) => (x_2.ReportId !== msg.fields[0])), model.LastId, model.ErrorMsg, model.InfoMsg);
        }
        case 4: {
            return new Model(model.Reports, model.LastId, msg.fields[0], model.InfoMsg);
        }
        case 5: {
            return new Model(model.Reports, model.LastId, model.ErrorMsg, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Reports, model.LastId, "Could not fetch reports.", model.InfoMsg);
            }
            else {
                const y = append(model.Reports, res.fields[0]);
                return new Model(y, (y.length === 0) ? model.LastId : maxBy((z) => z.ReportId, y, {
                    Compare: comparePrimitives,
                }).ReportId, model.ErrorMsg, model.InfoMsg);
            }
        }
    }
}

function fetchReports(row, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("/api/report/content?lid=%i"))(row);
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(ContentReport$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(ContentReport$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(ContentReport$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function dismiss(reportId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(reportId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/content", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(3, reportId));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(4, x_1.Message));
                    return Promise.resolve();
                }
            }
            else {
                dispatch(new Message(4, "Could not dismiss report"));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function deleteReview(reviewId, reportId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(reviewId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/review", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(3, reportId));
                    dispatch(new Message(5, "Review deleted"));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(4, x_1.Message));
                    return Promise.resolve();
                }
            }
            else {
                dispatch(new Message(4, "Could not delete review"));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function deleteReviewReply(reviewReplyId, reportId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(reviewReplyId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/review-reply", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(3, reportId));
                    dispatch(new Message(5, "Review reply deleted"));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(4, x_1.Message));
                    return Promise.resolve();
                }
            }
            else {
                dispatch(new Message(4, "Could not delete review reply"));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function deleteAssetPost(assetPostId, reportId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(assetPostId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/asset-post", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(3, reportId));
                    dispatch(new Message(5, "Asset post deleted"));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(4, x_1.Message));
                    return Promise.resolve();
                }
            }
            else {
                dispatch(new Message(4, "Could not delete asset post"));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function deleteAssetPostReply(assetPostReplyId, reportId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(assetPostReplyId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/asset-post-reply", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(3, reportId));
                    dispatch(new Message(5, "Asset post reply deleted"));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(4, x_1.Message));
                    return Promise.resolve();
                }
            }
            else {
                dispatch(new Message(4, "Could not delete asset post reply"));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function deleteGroupPost(groupPostId, reportId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(groupPostId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/group-post", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(3, reportId));
                    dispatch(new Message(5, "Group post deleted"));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(4, x_1.Message));
                    return Promise.resolve();
                }
            }
            else {
                dispatch(new Message(4, "Could not delete group post"));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function deleteGroupPostReply(groupPostReplyId, reportId, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(groupPostReplyId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/report/group-post-reply", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    dispatch(new Message(3, reportId));
                    dispatch(new Message(5, "Group post reply deleted"));
                    return Promise.resolve();
                }
                else {
                    dispatch(new Message(4, x_1.Message));
                    return Promise.resolve();
                }
            }
            else {
                dispatch(new Message(4, "Could not delete group post reply"));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function Report(reportInputProps) {
    let elems_4;
    const r = reportInputProps.r;
    const dispatch = reportInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const toggleDismiss = patternInput[0];
    const setToggleDismiss = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const toggleDelete = patternInput_1[0];
    const setToggleDelete = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setBanForm = patternInput_2[1];
    const pType = PostType_FromInt(r.ContentType, r.ContentId);
    let parent;
    const matchValue = r.PostInfo;
    if (matchValue != null) {
        const pi = matchValue;
        parent = toText(printf("%s by %s"))(pi.Parent)(r.Reported);
    }
    else {
        parent = "[Post info not fetched]";
    }
    const infoRow = (children) => createElement("div", {
        style: {
            padding: ((((((0 + "px ") + 5) + "px ") + 5) + "px ") + 5) + "px",
            backgroundColor: "#f6f6f6",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
    return MuiHelpers_createElement(Paper, [["elevation", 2], ["style", {
        padding: 10,
    }], (elems_4 = toList(delay(() => {
        let matchValue_1, userId;
        return append_1((matchValue_1 = patternInput_2[0], (matchValue_1 != null) ? ((userId = (matchValue_1 | 0), singleton_1(createElement(BanForm, {
            userId: userId,
            onClose: (didBan) => {
                if (didBan) {
                    dismiss(r.ReportId, dispatch);
                }
                setBanForm(void 0);
            },
        })))) : ((empty_1()))), delay(() => {
            let value_9, arg_5;
            return append_1(singleton_1(infoRow([(value_9 = ((arg_5 = toString_1(pType), toText(printf("Reported: %s for %s"))(arg_5)(parent))), createElement("span", {
                children: [value_9],
            }))])), delay(() => append_1(singleton_1(infoRow([createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: toText(printf("Reported by %s %s"))(r.Reporter)(r.ReportTime),
            })])), delay(() => append_1(singleton_1(infoRow([createElement("span", {
                style: {
                    fontWeight: "bold",
                },
                children: toString_1(ReportReason_FromInt_Z524259A4(r.ReasonId)),
            })])), delay(() => {
                let matchValue_2, pi_1, upd, matchValue_3, u;
                return append_1(singleton_1(infoRow([createElement("span", {
                    style: {
                        fontSize: 0.7 + "rem",
                    },
                    children: "Originally published: ",
                }), createElement("span", {
                    style: {
                        fontSize: 0.7 + "rem",
                    },
                    children: (matchValue_2 = r.PostInfo, (matchValue_2 != null) ? ((pi_1 = matchValue_2, (upd = ((matchValue_3 = pi_1.Updated, (matchValue_3 != null) ? ((u = matchValue_3, toText(printf("Updated %s"))(u))) : "")), toText(printf("%s. %s"))(pi_1.Published)(upd)))) : "[No info]"),
                })])), delay(() => {
                    let elems_1, matchValue_4;
                    return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {}], (elems_1 = [createElement("span", {
                        style: {
                            fontWeight: "bold",
                        },
                        children: "Content in question",
                    }), createElement("p", {
                        style: {
                            fontSize: 0.8 + "rem",
                            whiteSpace: "pre-wrap",
                        },
                        children: (matchValue_4 = r.PostInfo, (matchValue_4 != null) ? matchValue_4.Content : "[No info]"),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                        let elems_2;
                        return append_1(singleton_1(createElement("div", createObj(ofArray([["style", {}], (elems_2 = toList(delay(() => {
                            let value_57;
                            return (r.Comment.length > 0) ? append_1(singleton_1(createElement("span", {
                                style: {
                                    fontWeight: "bold",
                                },
                                children: toText(printf("Comment from %s"))(r.Reporter),
                            })), delay(() => singleton_1(createElement("p", {
                                style: {
                                    marginTop: 7,
                                    marginBottom: 7,
                                    fontStyle: "italic",
                                    whiteSpace: "pre-wrap",
                                    fontSize: 0.8 + "rem",
                                },
                                children: r.Comment,
                            })))) : singleton_1((value_57 = toText(printf("No comment provided by %s (reporter)"))(r.Reporter), createElement("span", {
                                children: [value_57],
                            })));
                        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                            let elems_3;
                            return singleton_1(createElement("div", createObj(ofArray([["style", {
                                display: "flex",
                                flexWrap: "wrap",
                            }], (elems_3 = [MuiHelpers_createElement(Button, toList(delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(toggleDismiss ? append_1(singleton_1(["variant", "contained"]), delay(() => singleton_1(["color", "primary"]))) : singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["children", "Dismiss"]), delay(() => append_1(singleton_1(["style", {
                                marginRight: 5,
                            }]), delay(() => singleton_1(["onClick", (_arg) => {
                                if (!toggleDismiss) {
                                    setToggleDismiss(true);
                                    setToggleDelete(false);
                                }
                                else {
                                    dismiss(r.ReportId, dispatch);
                                    setToggleDismiss(false);
                                }
                            }])))))))))))), MuiHelpers_createElement(Button, toList(delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(toggleDelete ? append_1(singleton_1(["variant", "contained"]), delay(() => singleton_1(["color", "secondary"]))) : singleton_1(["variant", "outlined"]), delay(() => {
                                let arg_17;
                                return append_1(singleton_1(["children", (arg_17 = toString_1(pType), toText(printf("Delete %s"))(arg_17))]), delay(() => append_1(singleton_1(["style", {
                                    marginRight: 5,
                                }]), delay(() => singleton_1(["onClick", (_arg_1) => {
                                    if (!toggleDelete) {
                                        setToggleDismiss(false);
                                        setToggleDelete(true);
                                    }
                                    else {
                                        const matchValue_5 = r.PostInfo;
                                        if (matchValue_5 != null) {
                                            const pi_3 = matchValue_5;
                                            switch (pType.tag) {
                                                case 0: {
                                                    deleteReview(pi_3.Id, r.ReportId, dispatch);
                                                    break;
                                                }
                                                case 1: {
                                                    deleteReviewReply(pi_3.Id, r.ReportId, dispatch);
                                                    break;
                                                }
                                                case 4: {
                                                    deleteAssetPost(pi_3.Id, r.ReportId, dispatch);
                                                    break;
                                                }
                                                case 5: {
                                                    deleteAssetPostReply(pi_3.Id, r.ReportId, dispatch);
                                                    break;
                                                }
                                                case 2: {
                                                    deleteGroupPost(pi_3.Id, r.ReportId, dispatch);
                                                    break;
                                                }
                                                case 3: {
                                                    deleteGroupPostReply(pi_3.Id, r.ReportId, dispatch);
                                                    break;
                                                }
                                                default: {
                                                    dispatch(new Message(4, "Delete functionality for this content not implemented"));
                                                }
                                            }
                                        }
                                        else {
                                            dispatch(new Message(4, "Invalid post info"));
                                        }
                                    }
                                }])))));
                            }))))))), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["style", {
                                marginRight: 5,
                            }], ["children", toText(printf("Ban %s"))(r.Reported)], ["onClick", (_arg_2) => {
                                setToggleDismiss(false);
                                setToggleDelete(false);
                                setBanForm(r.ReportedId);
                            }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["style", {
                                marginRight: 5,
                            }], ["children", toText(printf("Ban %s"))(r.Reporter)], ["onClick", (_arg_3) => {
                                setToggleDismiss(false);
                                setToggleDelete(false);
                                setBanForm(r.ReporterId);
                            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
                        }));
                    }));
                }));
            }))))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]);
}

function view(model, dispatch) {
    let elems_1;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 100 + "%",
    }], (elems_1 = toList(delay(() => append_1(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(1));
    })), delay(() => append_1(singleton_1(snackInfo(model.InfoMsg, () => {
        dispatch(new Message(2));
    })), delay(() => append_1((model.Reports.length === 0) ? singleton_1(createElement("span", {
        children: ["None"],
    })) : empty_1(), delay(() => append_1(singleton_1(map_1((x) => {
        let elems;
        return createElement("div", createObj(ofArray([["key", x.ReportId], ["style", {
            width: 100 + "%",
            maxWidth: 550,
            marginBottom: 20,
        }], (elems = [createElement(Report, {
            dispatch: dispatch,
            r: x,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, model.Reports)), delay(() => singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "More"], ["onClick", (_arg_2) => {
        fetchReports(model.LastId, dispatch);
    }]])))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ContentReports() {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchReports(model_1.LastId, dispatch);
    });
    return view(model_1, dispatch);
}

