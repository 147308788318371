import { toString, Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { GenericResponse$reflection, UserImage$reflection } from "./Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type, record_type, int32_type, option_type, string_type, array_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { map as map_1, append } from "./fable_modules/fable-library.3.7.22/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.22/String.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton, cons, ofArray, empty } from "./fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString as toString_1 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430, Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import { pageHeader, snackError } from "./ViewHelpers.js";
import { BanForm } from "./BanForm.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.0/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";

class Model extends Record {
    constructor(Images, ErrorMsg, Row, DeleteForm, BanForm) {
        super();
        this.Images = Images;
        this.ErrorMsg = ErrorMsg;
        this.Row = (Row | 0);
        this.DeleteForm = DeleteForm;
        this.BanForm = BanForm;
    }
}

function Model$reflection() {
    return record_type("ProfileImages.Model", [], Model, () => [["Images", array_type(UserImage$reflection())], ["ErrorMsg", option_type(string_type)], ["Row", int32_type], ["DeleteForm", option_type(int32_type)], ["BanForm", option_type(int32_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ImagesResponse", "DismissError", "Delete", "ToggleBanForm", "DeleteResponse"];
    }
}

function Message$reflection() {
    return union_type("ProfileImages.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserImage$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserImage$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", int32_type]], [["Item", option_type(int32_type)]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init() {
    return new Model([], void 0, 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.Images, void 0, model.Row, model.DeleteForm, model.BanForm);
        }
        case 2: {
            return new Model(model.Images, model.ErrorMsg, model.Row, msg.fields[0], model.BanForm);
        }
        case 4: {
            const res_1 = msg.fields[0];
            const matchValue = model.DeleteForm;
            if (matchValue != null) {
                const imgId = matchValue | 0;
                if (res_1.tag === 1) {
                    return new Model(model.Images, toString(res_1.fields[0]), model.Row, model.DeleteForm, model.BanForm);
                }
                else {
                    const x_2 = res_1.fields[0];
                    if (x_2.Result === "success") {
                        const y_1 = model.Images.filter((z) => (z.ImageId !== imgId));
                        console.log(some(y_1));
                        return new Model(y_1, model.ErrorMsg, model.Row, void 0, model.BanForm);
                    }
                    else {
                        return new Model(model.Images, x_2.Message, model.Row, model.DeleteForm, model.BanForm);
                    }
                }
            }
            else {
                return model;
            }
        }
        case 3: {
            return new Model(model.Images, model.ErrorMsg, model.Row, model.DeleteForm, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Images, "Something went wrong. Could not fetch images.", model.Row, model.DeleteForm, model.BanForm);
            }
            else {
                const x = res.fields[0];
                return new Model(append(model.Images, x), model.ErrorMsg, model.Row + x.length, model.DeleteForm, model.BanForm);
            }
        }
    }
}

function fetchMore(row, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        const requestPath = toText(printf("/api/profilepicture/latest?row=%i"))(row);
        return ((decoder = Auto_generateBoxedDecoder_79988AEF(array_type(UserImage$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(UserImage$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(array_type(UserImage$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_4) => (new FSharpResult$2(1, new FetchError(3, arg_4)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, _arg_2));
            return Promise.resolve();
        });
    }));
}

function deleteImage(imgId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(2, imgId));
        return (new Promise(resolve => setTimeout(resolve, 400))).then(() => {
            let body, decoder, data_1, caseStrategy_3, extra_3;
            return ((body = Auto_generateBoxedEncoder_Z20B7B430(int32_type, void 0, void 0, void 0)(imgId), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "DELETE"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString_1(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/profilepicture/delete", properties_3).then((_arg_1) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                            let matchValue;
                            return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_3) => {
                dispatch(new Message(4, _arg_3));
                return Promise.resolve();
            });
        });
    }));
}

function imageList(model, dispatch) {
    let elems_4;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_4 = toList(delay(() => append_1(singleton_1(snackError(model.ErrorMsg, () => {
        dispatch(new Message(1));
    })), delay(() => {
        let matchValue, uId;
        return append_1((matchValue = model.BanForm, (matchValue != null) ? ((uId = (matchValue | 0), singleton_1(createElement(BanForm, {
            userId: uId,
            onClose: (_arg_1) => {
                dispatch(new Message(3, void 0));
            },
        })))) : ((empty_1()))), delay(() => singleton_1(map_1((x) => {
            let elems_3, elems_2, elems, value_28, value_29, value_30, elems_1;
            return createElement("div", createObj(ofArray([["key", x.ImageId], ["style", {
                border: (((1 + "px ") + "solid") + " ") + "#454545",
                marginBottom: 20,
                display: "flex",
            }], (elems_3 = [createElement("img", {
                style: {
                    maxWidth: 300,
                    maxHeight: 300,
                    width: 95 + "vw",
                },
                src: toText(printf("/api/profilepicture/%i"))(x.ImageId),
            }), createElement("div", createObj(ofArray([["style", {
                padding: 10,
            }], (elems_2 = [createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                fontSize: 0.8 + "rem",
            }], (elems = [(value_28 = toText(printf("User: %s"))(x.Username), createElement("span", {
                children: [value_28],
            })), (value_29 = toText(printf("UserId: %i"))(x.UserId), createElement("span", {
                children: [value_29],
            })), (value_30 = toText(printf("Uploaded: %s"))(x.Updated), createElement("span", {
                children: [value_30],
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(singleton((elems_1 = [MuiHelpers_createElement(Button, [["children", "Delete"], ["variant", "outlined"], ["size", "small"], ["onClick", (_arg_2) => {
                const pr = deleteImage(x.ImageId, dispatch);
                pr.then();
            }]]), MuiHelpers_createElement(Button, [["children", "Ban"], ["variant", "outlined"], ["size", "small"], ["onClick", (_arg_3) => {
                dispatch(new Message(3, x.UserId));
            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
        }, model.Images))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

function view(model, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
    }], (elems = [pageHeader("User profile images"), createElement("br", {}), imageList(model, dispatch), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "More"], ["onClick", (_arg) => {
        const pr = fetchMore(model.Row, dispatch);
        pr.then();
    }]]), snackError(model.ErrorMsg, () => {
        dispatch(new Message(1));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export const profileImages = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchMore(model_1.Row, dispatch);
        pr.then();
    }, []);
    return view(model_1, dispatch);
}, void 0, uncurry(2, void 0), void 0, "profileImages", "/home/runner/work/aksjeio-admin/aksjeio-admin/src/Client/ProfileImages.fs", 196);

