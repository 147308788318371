import { Union, Record } from "./fable_modules/fable-library.3.7.22/Types.js";
import { equals, obj_type, union_type, record_type, lambda_type, unit_type, bool_type, option_type, class_type, string_type, int32_type } from "./fable_modules/fable-library.3.7.22/Reflection.js";
import { BanForm as BanForm_1, BanForm$reflection, GenericResponse$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.22/Choice.js";
import { parse, toString as toString_1, addDays, now } from "./fable_modules/fable-library.3.7.22/Date.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, Auto_generateBoxedEncoder_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { map, defaultArg, some } from "./fable_modules/fable-library.3.7.22/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library.3.7.22/List.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.22/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "./fable_modules/fable-library.3.7.22/Util.js";
import { createElement } from "react";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./fable_modules/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/Interop.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.7.22/Seq.js";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { toISO8601 } from "./Utils.js";
import DialogActions from "@material-ui/core/DialogActions";
import { useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.1.64.0/React.fs.js";

class Model extends Record {
    constructor(UserId, Reason, Until, ErrorMsg, Banned, OnClose) {
        super();
        this.UserId = (UserId | 0);
        this.Reason = Reason;
        this.Until = Until;
        this.ErrorMsg = ErrorMsg;
        this.Banned = Banned;
        this.OnClose = OnClose;
    }
}

function Model$reflection() {
    return record_type("BanForm.Model", [], Model, () => [["UserId", int32_type], ["Reason", string_type], ["Until", class_type("System.DateTime")], ["ErrorMsg", option_type(string_type)], ["Banned", bool_type], ["OnClose", lambda_type(bool_type, unit_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateReason", "UpdateUntil", "BanResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("BanForm.Message", [], Message, () => [[["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(x, y) {
    let copyOfStruct;
    return new Model(x, "", (copyOfStruct = now(), addDays(copyOfStruct, 7)), void 0, false, y);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.UserId, model.Reason, msg.fields[0], model.ErrorMsg, model.Banned, model.OnClose);
        }
        case 2: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.UserId, model.Reason, model.Until, "Could not ban user", model.Banned, model.OnClose);
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.Result === "success") {
                    return new Model(model.UserId, model.Reason, model.Until, model.ErrorMsg, true, model.OnClose);
                }
                else {
                    return new Model(model.UserId, model.Reason, model.Until, x_2.Message, model.Banned, model.OnClose);
                }
            }
        }
        case 3: {
            return new Model(model.UserId, model.Reason, model.Until, void 0, model.Banned, model.OnClose);
        }
        default: {
            return new Model(model.UserId, msg.fields[0], model.Until, model.ErrorMsg, model.Banned, model.OnClose);
        }
    }
}

function ban(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_Z20B7B430(BanForm$reflection(), void 0, void 0, void 0)(x), (decoder = Auto_generateBoxedDecoder_79988AEF(GenericResponse$reflection(), void 0, void 0), (data_1 = some(body), (caseStrategy_3 = (void 0), (extra_3 = (void 0), (() => {
            let properties_2, headers_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, "cors")), (properties_2 = ofArray([new Types_RequestProperties(0, "POST"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_3, extra_3)(data_1_1))), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/user/ban", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(GenericResponse$reflection(), caseStrategy_3, extra_3)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve(equals(GenericResponse$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(2, _arg_2));
            return Promise.resolve();
        });
    }));
}

function form$0027(model, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems = [createElement("span", {
        children: ["Ban user"],
    }), createElement("textarea", {
        defaultValue: model.Reason,
        onChange: (ev) => {
            dispatch(new Message(0, ev.target.value));
        },
        style: {
            minWidth: 350,
            minHeight: 50,
            marginBottom: 20,
        },
    }), MuiHelpers_createElement(TextField, [["type", "date"], ["value", toString_1(model.Until, "yyyy-MM-dd")], ["onChange", (e) => {
        const x = Browser_Types_Event__Event_get_Value(e);
        try {
            dispatch(new Message(1, parse(x)));
        }
        catch (matchValue) {
        }
    }], ["label", "Ban until"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function isBanned(model) {
    const children = singleton(createElement("span", {
        children: ["User banned"],
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function view(model, dispatch) {
    let elements_2, children, children_1;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_1, v) => {
        model.OnClose(model.Banned);
    }], (elements_2 = [MuiHelpers_createElement(DialogTitle, [["children", "Ban user"]]), (children = toList(delay(() => ((!model.Banned) ? singleton_1(form$0027(model, dispatch)) : singleton_1(isBanned(model))))), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Cancel"], ["onClick", (_arg_2) => {
        model.OnClose(model.Banned);
    }]])), delay(() => ((!model.Banned) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "contained"], ["color", "secondary"], ["children", "Ban"], ["onClick", (_arg_3) => {
        const pr = ban(new BanForm_1(model.UserId, model.Reason, toISO8601(model.Until)), dispatch);
        pr.then();
    }]])) : empty_1()))))), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
}

export function BanForm(banFormInputProps) {
    const onClose = banFormInputProps.onClose;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(banFormInputProps.userId, onClose));
    return view(patternInput[0], patternInput[1]);
}

